import React from 'react';
import ReactSelect, { components } from 'react-select';

import Price from '../../SignUp/Price/Price';

const ValueContainerComponent = (props, customProps) => {
  let selectedCount = null;
  const { getValue } = props;
  const { price } = customProps;
  const valuesList = getValue();

  if (valuesList.length > 0) {
    selectedCount = valuesList[0].value;
  }
  return (
    <>
      <components.ValueContainer {...props} />
      {price && selectedCount !== null && (
        <Price price={selectedCount * price} />
      )}
    </>
  );
};

export default function CustomSelect(props) {
  // defaultValue: {value: 'value', label: 'Label'}
  // options: [{value: 'value', label: 'Label'}, {}, ...]
  // price: number
  const {
    value,
    defaultValue,
    defaultInputValue,
    options,
    price,
    changed,
    isSearchable = true,
    autoComplete,
  } = props;

  return (
    <ReactSelect
      onChange={changed}
      value={value}
      defaultValue={defaultValue}
      options={options}
      defaultInputValue={defaultInputValue}
      autoFocus={false}
      isSearchable={isSearchable}
      autoComplete={autoComplete}
      components={{
        ValueContainer: (props) =>
          ValueContainerComponent({ ...props }, { price }),
      }}
      styles={{
        indicatorSeparator: (styles) => ({
          ...styles,
          display: 'none',
        }),
        dropdownIndicator: (styles) => ({
          ...styles,
          color: '#0046A4',
        }),
        control: (styles) => ({
          ...styles,
          width: '100%',
          border: 'none',
          borderRadius: '0px',
          borderBottom: '1px solid #D0D7E2',
          cursor: 'pointer',
          boxShadow: 'none',
          ':hover': {
            ...styles[':hover'],
            borderBottomColor: '#8C96A3',
          },
        }),
        container: (styles) => ({
          ...styles,
          border: 'none',
          outline: 'none',
        }),
      }}
    />
  );
}
