import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import SignInData from '../containers/SignIn/SignInData/SignInData';
import OauthData from '../containers/SignIn/OauthData/OauthData';
import SignUpData from '../containers/SignUp/SignUpData/SignUpData';
import PasswordReset from '../containers/PasswordReset/PasswordReset';
import PasswordResetConfirm from '../containers/PasswordReset/PasswordResetConfirm/PasswordResetConfirm';
import UnsupportedBrowser from '../containers/SignUp/SignUpData/UnsupportedBrowser/UnsupportedBrowser';
import TrainingWebinars from '../containers/TrainingWebinars/TrainingWebinars';
import CancellationFeedback from '../containers/CancellationFeedback/CancellationFeedback';
import PinConfirm from '../containers/PinConfirm/PinConfirm';
import SMTPSettingsConfirmation from '../containers/SMTPSettingsConfirmation/SMTPSettingsConfirmation';

const Router = () => {
  return (
    <Switch>
      <Route path="/login/" exact component={SignInData} />
      <Route path="/oauth/authorize/" exact component={OauthData} />
      <Route path="/oauth/zapier_authorize/" exact component={OauthData} />
      <Route path="/sign-up/step-:stepNum" component={SignUpData} />
      <Route path="/sign-up/" component={SignUpData} />
      <Route path="/password-reset/" exact component={PasswordReset} />
      <Route
        path="/integrations/bombbomb/redirect_uri/"
        exact
        component={PinConfirm}
      />
      <Route
        path="/password-reset/confirm/:token/"
        exact
        component={PasswordResetConfirm}
      />
      <Route path="/browser_select/" exact component={UnsupportedBrowser} />
      <Route
        path="/single/training-webinars/"
        exact
        component={TrainingWebinars}
      />
      <Route
        path="/single/cancellation-feedback/"
        exact
        component={CancellationFeedback}
      />
      <Route
        path="/single/smtp-settings-confirmation/:token/"
        exact
        component={SMTPSettingsConfirmation}
      />
      <Redirect to="/login/" />
    </Switch>
  );
};

export default Router;
