import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import AccountExists from './AccountExists';
import Button from '../../../../components/UI/Button/Button';
import Form from '../../../../components/UI/Form/Form';
import CallTollFree from '../../../../components/Shared/CallTollFree/CallTollFree';
import Prompt from '../../../../components/Shared/Prompt/Prompt';
import styles from './StepOne.module.css';
import * as actions from '../../../../redux/actions/index';
import * as api from '../../../../api/SignUp';

const StepOne = (props) => {
  const [inputsScheme, setInputsScheme] = useState({
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        placeholder: 'email@example.com',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
        isEmail: true,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Email:',
    },
  });
  const [foundCancelledAccount, setFoundCancelledAccount] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [submittedformData, setSubmittedformData] = useState(null);
  const [pendingAccount, setPendingAccount] = useState(false);
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

  const {
    onSetStep,
    onSetStepCompleted,
    onSetEmail,
    onUpdateSignUpData,
    sendGAEvent,
    currentStep,
    signUpData,
    history,
    formErrors,
  } = props;
  const emailValue = signUpData && signUpData.email ? signUpData.email : '';

  useEffect(() => {
    if (currentStep !== 1) {
      onSetStep(1);
    }
  }, [onSetStep, currentStep]);

  const onSubmitHandler = (event, formData, inputsScheme) => {
    event.preventDefault();
    let isValid = true;
    if ('email' in formData && 'confirmEmail' in formData) {
      if (
        formData.email.toLowerCase() === formData.confirmEmail.toLowerCase()
      ) {
        setIsEmailConfirmed(true);
        isValid = true;
      } else {
        setErrors('Your email and confirmation email do not match.');
        setIsEmailConfirmed(false);
        isValid = false;
      }
    }
    if (isValid) {
      onSetEmail(formData.email);
      onSetStepCompleted(1);
      setSubmittedformData(formData);
      setInputsScheme({
        ...inputsScheme,
        ...{
          email: {
            elementType: 'input',
            elementConfig: {
              type: 'email',
              placeholder: 'email@example.com',
              autoComplete: 'off',
            },
            value: formData.email,
            validation: {
              required: true,
              isEmail: true,
            },
            valid: false,
            error: '',
            touched: false,
            label: 'Email:',
          },
        },
      });
    }
  };

  const showConfirmEmailInput = (inputsScheme) => {
    setInputsScheme({
      ...inputsScheme,
      ...{
        confirmEmail: {
          elementType: 'input',
          elementConfig: {
            type: 'email',
            placeholder: 'email@example.com',
            autoComplete: 'off',
          },
          value: '',
          validation: {
            required: true,
            isEmail: true,
          },
          valid: false,
          error: '',
          touched: false,
          label: 'Confirm Email:',
        },
      },
    });
  };

  useEffect(
    () => {
      if (submittedformData) {
        setIsLoading(true);
        setErrors({});
        api
          .getLoginInfoByEmail(submittedformData.email)
          .then((response) => {
            setIsLoading(false);
            if (response.data.status === 'invalid_data') {
              setErrors(response.data.message);
            } else if (response.data.status === 'free') {
              if (isEmailConfirmed) {
                sendGAEvent('Email Input -> Dialer and Data choices');
                history.push('/sign-up/step-2');
              } else {
                showConfirmEmailInput(inputsScheme);
                setSubmittedformData(null);
              }
            } else if (response.data.status === 'busy.active') {
              history.push(
                `/login/?status=busy.active&extraData=${encodeURIComponent(
                  JSON.stringify({
                    pin: response.data.pin,
                    email: response.data.email,
                  })
                )}`
              );
            } else if (response.data.status === 'busy.cancelled') {
              setFoundCancelledAccount({
                email: submittedformData.email,
                pin: response.data.pin,
                passwordResetLink: response.data.password_reset_url,
              });
            } else if (response.data.status === 'busy.pending') {
              setPendingAccount(true);
            }
          })
          .catch(() => {
            setIsLoading(false);
            setErrors('Server error.');
          });
      } else if (inputsScheme.email.value !== emailValue) {
        setInputsScheme({
          ...inputsScheme,
          ...{
            email: {
              elementType: 'input',
              elementConfig: {
                type: 'email',
                placeholder: 'email@example.com',
                autoComplete: 'off',
              },
              value: emailValue,
              validation: {
                required: true,
                isEmail: true,
              },
              valid: false,
              error: '',
              touched: false,
              label: 'Email:',
            },
          },
        });
      }
    },
    // eslint-disable-next-line
    [
      submittedformData,
      inputsScheme,
      signUpData,
      history,
      emailValue,
      isEmailConfirmed,
    ]
  );

  // Receive errors from redux
  useEffect(() => {
    if (
      formErrors &&
      formErrors.emails_form &&
      Object.keys(formErrors.emails_form).length > 0
    ) {
      setErrors(formErrors.emails_form);
    }
  }, [formErrors]);

  const cancelAccountExistsStep = () => {
    setFoundCancelledAccount(null);
  };

  const newAccountWithPrefill = (token) => {
    setFoundCancelledAccount(null);
    setIsLoading(true);
    api
      .signUpPrefill(token)
      .then((response) => {
        setIsLoading(false);
        onUpdateSignUpData(response.data.prefill_data);
        history.push('/sign-up/step-2?new_account=1');
      })
      .catch(() => {
        setIsLoading(false);
        setErrors('Server error.');
      });
  };

  let stepBody = (
    <>
      {pendingAccount && (
        <Prompt
          isOpen
          style={{ width: '400px' }}
          title="Warning!"
          onClose={() => setPendingAccount(false)}
          buttons={[
            {
              title: 'Ok',
              clicked: () => setPendingAccount(false),
              type: 'success',
            },
          ]}
        >
          <div style={{ lineHeight: '1.5' }}>
            Account with this email already exists but sign-up wasn`t finished
            yet.
          </div>
        </Prompt>
      )}
      <div className={styles.Title}>Sign Up:</div>
      <Form
        inputsScheme={inputsScheme}
        onSubmit={onSubmitHandler}
        style={{ paddingTop: '18px' }}
        errorsScheme={errors}
        inputLabelStyle={{ width: '120px' }}
      >
        <Button
          type="submit"
          isBtnNext
          style={{ marginTop: '20px', width: '146px' }}
          isLoading={isLoading}
        >
          Next
        </Button>
      </Form>
      <CallTollFree />
    </>
  );
  if (foundCancelledAccount) {
    stepBody = (
      <AccountExists
        pin={foundCancelledAccount.pin}
        email={foundCancelledAccount.email}
        resetPasswordLink={foundCancelledAccount.passwordResetLink}
        onPrev={cancelAccountExistsStep}
        onNewAccount={newAccountWithPrefill}
      />
    );
  }

  return <div className={styles.Step}>{stepBody}</div>;
};

const mapStateToProps = (state) => {
  return {
    currentStep: state.signUp.stepNumber,
    stepCompleted: state.signUp.stepCompleted,
    signUpData: state.signUp.data,
    formErrors: state.signUp.formErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetStep: (stepNum) => dispatch(actions.setStepNumber(stepNum)),
    onSetStepCompleted: (stepNum) =>
      dispatch(actions.setStepCompleted(stepNum)),
    onSetEmail: (email) => dispatch(actions.setAccountEmail(email)),
    onUpdateSignUpData: (signUpdata) =>
      dispatch(actions.updateSignUpData(signUpdata)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
