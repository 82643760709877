import React from 'react';

import styles from './Header.module.css';
// import extraStyles from '../TwoColumnsLayout.module.css';
import Logo from '../../../../components/Logo/Logo';
import HeaderMenu from '../../../../components/Header/HeaderMenu/HeaderMenu';

export default function Header() {
  return (
    <div className={styles.Item}>
      <div className={styles.ItemInner}>
        <div style={{ flex: 0.5 }}>
          <Logo isDark style={{ width: '104px', height: '47px' }} />
        </div>
        <div style={{ flex: 1.5 }}>
          <HeaderMenu />
        </div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <a className={styles.HeaderLogoLink} href="/login/">
            Login
          </a>
          <a className={styles.HeaderLogoLink} href="/sign-up/">
            Sign Up
          </a>
        </div>
      </div>
    </div>
  );
}
