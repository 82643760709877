import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './HeaderLogo.module.css';
import Logo from '../../Logo/Logo';

export default function HeaderLogo() {
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const source = searchParams.get('source');
  const hideLoginSignupBtns = source === 'www.mojosells.com';

  return (
    <div className={[styles.HeaderLogo].join(' ')}>
      <Logo />
      <div className={styles.HeaderLogoButtonWrap}>
        {!hideLoginSignupBtns && (
          <>
            <a className={styles.HeaderLogoLink} href="/login/">
              Login
            </a>
            <a className={styles.HeaderLogoLink} href="/sign-up/">
              Sign Up
            </a>
          </>
        )}
      </div>
    </div>
  );
}
