import React from 'react';
import Modal from 'react-modal';

import styles from './Prompt.module.scss';
import PopupCloseIcon from '../../../assets/icons/popup-close-icon.svg';

Modal.setAppElement('#root');
const customStyles = {
  content: {
    // width: 400,
    // height: 200,
    // marginTop: 'auto',
    // marginBottom: 'auto',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // borderRadius: 4,
    // padding: '5px 20px',

    // For Dynamic Height
    // border: '0',
    borderRadius: '4px',
    bottom: 'auto',
    minHeight: '10rem',
    left: '50%',
    padding: '5px 20px', // padding: '2rem',
    position: 'fixed',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    minWidth: '20rem',
    width: '80%',
    maxWidth: '60rem',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    boxShadow: '0 0 40px 0 rgba(0, 0, 0, 0.2)',
  },
};

export default function Prompt(props) {
  const { style, title, buttons, children, isOpen, onClose, hideClose } = props;

  let promptStyles = customStyles;
  if (style) {
    const updatedContentStyle = { ...promptStyles.content, ...style };
    promptStyles = { ...promptStyles, content: updatedContentStyle };
  }

  const buttonsBlock = buttons.map((btnInfo) => {
    return (
      <button
        key={btnInfo.title}
        className={
          btnInfo.type === 'success'
            ? styles.ModelFooterConfirmBtn
            : styles.ModelFooterCloseBtn
        }
        type="button"
        onClick={() => {
          btnInfo.clicked();
        }}
      >
        {btnInfo.title}
      </button>
    );
  });

  return (
    <Modal isOpen={isOpen} style={promptStyles} shouldCloseOnOverlayClick>
      <div className={styles.ModalInner}>
        <div className={styles.ModalHeader}>
          <div className={styles.ModalHeaderLabel}>{title}</div>
          {!hideClose && (
            <button
              className={styles.ModalHeaderCloseBtn}
              type="button"
              onClick={() => {
                onClose();
              }}
            >
              <img src={PopupCloseIcon} alt="close" />
            </button>
          )}
        </div>

        <div className={styles.ModalContent}>
          <div className={styles.ModalContentText}>{children}</div>
        </div>

        <div className={styles.ModelFooter}>{buttonsBlock}</div>
      </div>
    </Modal>
  );
}
