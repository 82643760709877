import React from 'react';

import styles from './Header.module.css';
import extraStyles from '../TwoColumnsLayout.module.css';
import HeaderLogo from '../../../../components/Header/HeaderLogo/HeaderLogo';
import HeaderMenu from '../../../../components/Header/HeaderMenu/HeaderMenu';

export default function Header() {
  return (
    <div className={styles.Item}>
      <div
        className={[
          extraStyles.TwoColumnsLayoutItem,
          extraStyles.TwoColumnsLayoutItemDark,
        ].join(' ')}
      >
        <div
          className={[
            extraStyles.TwoColumnsLayoutItemInner,
            extraStyles.TwoColumnsLayoutItemInnerDark,
          ].join(' ')}
        >
          <HeaderLogo />
        </div>
      </div>
      <div
        className={[
          extraStyles.TwoColumnsLayoutItem,
          extraStyles.TwoColumnsLayoutItemLight,
        ].join(' ')}
      >
        <div
          className={[
            extraStyles.TwoColumnsLayoutItemInner,
            extraStyles.TwoColumnsLayoutItemInnerLight,
          ].join(' ')}
        >
          <HeaderMenu />
        </div>
      </div>
    </div>
  );
}
