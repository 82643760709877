import React from 'react';
import styles from './Checkbox.module.scss';

export default function Checkbox(props) {
  const {
    children,
    style,
    name,
    isChecked,
    changed,
    labelStyle,
    isLarge,
    refProp,
    autoComplete,
  } = props;
  const checkBoxClasses = [styles.Checkbox];
  if (isLarge) {
    checkBoxClasses.push(styles.CheckboxLarge);
  }
  return (
    <label
      htmlFor={`id_${name}`}
      className={styles.Label}
      style={labelStyle}
      ref={refProp}
    >
      <input
        style={style}
        type="checkbox"
        id={`id_${name}`}
        name={name}
        className={styles.Input}
        onChange={changed}
        checked={isChecked}
        autoComplete={autoComplete}
      />
      <span className={checkBoxClasses.join(' ')} />
      <span className={styles.Text}>{children}</span>
    </label>
  );
}
