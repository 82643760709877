import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './PinConfirm.module.scss';
import Form from '../../components/UI/Form/Form';
import Button from '../../components/UI/Button/Button';
import CallTollFree from '../../components/Shared/CallTollFree/CallTollFree';

import * as api from '../../api';

export default function PinConfirm() {
  const [inputsScheme, setInputsScheme] = useState({
    pin: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Enter Account Number',
        maxLength: '6',
      },
      value: '',
      validation: {
        required: true,
        isNumeric: true,
        maxLength: 6,
        minLenght: 6,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Account Number:',
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const codeURISearchParam = searchParams.get('code');

  const handleOnSubmit = useCallback(
    async (event, formData) => {
      event.preventDefault();

      setInputsScheme((prevState) => {
        return { ...prevState, pin: { ...prevState.pin, value: formData.pin } };
      });
      setIsLoading(true);

      try {
        const { data } = await api.validatePin(
          formData.pin,
          codeURISearchParam
        );

        window.location.href = data.redirect_uri;
      } catch (e) {
        if (e.response) {
          setErrors(e.response.data.errors);
        } else {
          setErrors(e.message);
        }
      }
      setIsLoading(false);
    },
    [setInputsScheme, codeURISearchParam]
  );

  return (
    <div className={styles.PinConfirm}>
      <div className={styles.Title}>Confirmation</div>
      <div className={styles.PinConfirmInner}>
        {codeURISearchParam ? (
          <>
            <div className={styles.SubTitle} style={{ fontSize: '19px' }}>
              Please enter your 6 digit Account Number to confirm BombBomb
              integration:
            </div>
            <Form
              inputsScheme={inputsScheme}
              onSubmit={handleOnSubmit}
              style={{ paddingTop: '18px' }}
              errorsScheme={errors}
            >
              <Button
                type="submit"
                isBtnNext
                style={{ marginTop: '45px', width: '146px' }}
                isLoading={isLoading}
              >
                Submit
              </Button>
            </Form>

            <CallTollFree />
          </>
        ) : (
          <>
            <div>Oops! Page is no longer valid.</div>
            <Button
              type="button"
              style={{ marginTop: '57px', width: '146px' }}
              onClick={() => history.push('/login/')}
            >
              Back to Login
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
