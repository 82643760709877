import React from 'react';
import { useHistory } from 'react-router-dom';
import CallTollFree from '../../../../components/Shared/CallTollFree/CallTollFree';
import styles from './UnsupportedBrowser.module.scss';
import firefoxImg from '../../../../assets/images/firefox-browser.svg';
import safariImg from '../../../../assets/images/safari-browser.svg';
import chromeImg from '../../../../assets/images/chrome-browser.svg';
import edgeImg from '../../../../assets/images/edge-browser.svg';

const UnsupportedBrowser = () => {
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const statusSearchParam = searchParams.get('status');
  return (
    <div className={styles.UnsupportedBrowser}>
      {statusSearchParam && statusSearchParam === 'registered' && (
        <div className={styles.SuccessWrap}>
          <div className={styles.SuccessTitle}>Success!</div>
          <div className={styles.SuccessContent}>
            Your account is currently being set up. This can take 5-10 minutes,
            please wait for your confirmation e-mail to log into Mojo.
          </div>
        </div>
      )}

      <div
        style={{
          color: '#646C78',
          fontSize: '24px',
          fontWeight: '300',
          lineHeight: '30px',
        }}
      >
        Our site(s) do not support any versions of Internet Explorer.
      </div>

      <div
        style={{
          marginTop: '32px',
          fontSize: '20px',
          fontWeight: '600',
          color: '#3F4752',
          lineHeight: '24px',
        }}
      >
        Please continue with a Google Chrome, Mozilla Firefox, Safari or
        Microsoft Edge.
      </div>

      <div
        style={{
          marginTop: '16px',
          fontSize: '16px',
          color: '#3F4752',
          lineHeight: '24px',
        }}
      >
        Please check your programs to see if you have any of these internet
        browsers installed on your computer. If you do not have any of the
        preferred internet browsers already installed on your computer please
        click on one of the below icons to install one.
      </div>

      <div className={styles.IconsContainer}>
        <div className={styles.IconWrap}>
          <a
            href="https://www.google.com/chrome/browser/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <img src={chromeImg} alt="Chrome" />
            </div>

            <div className={styles.IconLabel}>Chrome</div>
          </a>
        </div>
        <div className={styles.IconWrap}>
          <a
            href="https://www.mozilla.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <img src={firefoxImg} alt="Firefox" />
            </div>

            <div className={styles.IconLabel}>Firefox</div>
          </a>
        </div>
        <div className={styles.IconWrap}>
          <a
            href="http://support.apple.com/downloads/#safari"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <img src={safariImg} alt="Safari" />
            </div>

            <div className={styles.IconLabel}>Safari</div>
          </a>
        </div>
        <div className={styles.IconWrap}>
          <a
            href="https://www.microsoft.com/en-us/edge"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <img src={edgeImg} alt="Microsoft Edge" />
            </div>

            <div className={styles.IconLabel}>Microsoft Edge</div>
          </a>
        </div>
      </div>
      <CallTollFree style={{ marginTop: '40px' }} />
    </div>
  );
};

export default UnsupportedBrowser;
