import ax from './axios';
import { formDataFromParams } from './utils';

export const validatePin = (pin, code) => {
  return ax({
    url: '/integrations/bombbomb/validate_pin/',
    method: 'post',
    data: formDataFromParams({ pin, code }),
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });
};
