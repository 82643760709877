import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import PhoneInput from './PhoneInput/PhoneInput';
import SelectInput from './SelectInput/SelectInput';
import Input from '../../../../components/UI/Input/Input';
import styles from './StepFour.module.scss';
import Checkbox from '../../../../components/UI/Inputs/Checkbox/Checkbox';
import Button from '../../../../components/UI/Button/Button';
import { checkValidity, updateObject } from '../../../../shared/utility';
import * as actions from '../../../../redux/actions/index';

const getSelectedOption = (options, value) => {
  let searchedOption = null;
  Object.values(options).forEach((option) => {
    if (option.value === value) {
      searchedOption = option;
    }
  });
  return searchedOption;
};

const getSelectedState = (states, value) => {
  let result = null;
  Object.values(states).forEach((data) => {
    Object.values(data.options).forEach((stateOption) => {
      if (stateOption.value === value) {
        result = stateOption;
      }
    });
  });
  return result;
};

const customerInfoFormKeys = [
  'first_name',
  'last_name',
  'company',
  'mobile_phone',
  'phone',
  'address',
  'city',
  'state',
  'zip_code',
];

const checkboxesFormFirstColKeys = [
  'broker',
  'coach',
  'friend_colleague',
  'facebook_ad',
  'facebook_group',
];

const checkboxesFormSecondColKeys = [
  'google_search',
  'integration_partner',
  'past_client',
  'youtube',
  'other_checkbox',
];

const accInfoFormKeys = [
  'password',
  'password2',
  // 'industry',
  'time_zone',
  'first_security_question',
  'first_question_answer',
  'second_security_question',
  'second_question_answer',
];

const matchErrorMsg = 'Two password fields don`t match';

const StepFour = (props) => {
  const {
    onSetStep,
    onSetStepCompleted,
    onUpdateSignUpData,
    sendGAEvent,
    renameGAEvent,
    stepNumber,
    stepCompleted,
    signUpData,
    history,
    states,
    formErrors,
    accountType,
    dataAndDialerChoice,
  } = props;
  const customerFormErrors =
    formErrors && formErrors.customer_form ? formErrors.customer_form : null;
  const inputScheme = {
    first_name: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: '',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
        maxLength: 59,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'First Name:',
      inputWrapRef: React.createRef(),
    },
    last_name: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: '',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
        maxLength: 60,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Last Name:',
      inputWrapRef: React.createRef(),
    },
    company: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: '',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
        maxLength: 255,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Company Name:',
      inputWrapRef: React.createRef(),
    },
    mobile_phone: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: '',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
        minLength: 10,
        maxLength: 10,
        isPhone: true,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Mobile Phone:',
      inputWrapRef: React.createRef(),
    },
    phone: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: '',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: false,
        minLength: 10,
        maxLength: 10,
        isPhone: true,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Office Phone:',
      inputWrapRef: React.createRef(),
    },
    address: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: '',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
        maxLength: 60,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Address:',
      inputWrapRef: React.createRef(),
    },
    city: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: '',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
        maxLength: 60,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'City:',
      inputWrapRef: React.createRef(),
    },
    state: {
      elementType: 'select',
      elementConfig: {
        options: [],
        placeholder: 'Select State',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'State:',
      inputWrapRef: React.createRef(),
    },
    zip_code: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: '',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
        maxLength: 60,
        isPostalCode: true,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Zip Code:',
      inputWrapRef: React.createRef(),
    },
    broker: {
      elementType: 'checkbox',
      value: false,
      validation: {
        required: false,
      },
      valid: true,
      error: '',
      touched: false,
      label: 'Broker:',
      inputWrapRef: React.createRef(),
    },
    coach: {
      elementType: 'checkbox',
      value: false,
      validation: {
        required: false,
      },
      valid: true,
      error: '',
      touched: false,
      label: 'Coach:',
    },
    friend_colleague: {
      elementType: 'checkbox',
      value: false,
      validation: {
        required: false,
      },
      valid: true,
      error: '',
      touched: false,
      label: 'Friend/Colleague:',
    },
    facebook_ad: {
      elementType: 'checkbox',
      value: false,
      validation: {
        required: false,
      },
      valid: true,
      error: '',
      touched: false,
      label: 'Facebook Ad:',
    },
    facebook_group: {
      elementType: 'checkbox',
      value: false,
      validation: {
        required: false,
      },
      valid: true,
      error: '',
      touched: false,
      label: 'Facebook Group:',
    },
    google_search: {
      elementType: 'checkbox',
      value: false,
      validation: {
        required: false,
      },
      valid: true,
      error: '',
      touched: false,
      label: 'Google Search:',
    },
    integration_partner: {
      elementType: 'checkbox',
      value: false,
      validation: {
        required: false,
      },
      valid: true,
      error: '',
      touched: false,
      label: 'Integration Partner:',
    },
    past_client: {
      elementType: 'checkbox',
      value: false,
      validation: {
        required: false,
      },
      valid: true,
      error: '',
      touched: false,
      label: 'I am a previous Mojo client:',
    },
    youtube: {
      elementType: 'checkbox',
      value: false,
      validation: {
        required: false,
      },
      valid: true,
      error: '',
      touched: false,
      label: 'Youtube:',
    },
    other_checkbox: {
      elementType: 'checkbox',
      value: false,
      validation: {
        required: false,
      },
      valid: true,
      error: '',
      touched: false,
      label: 'Other:',
    },
    other: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Your choice',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: false,
      },
      valid: true,
      error: '',
      touched: false,
      inputWrapRef: React.createRef(),
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: '',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
        minLength: 6,
        maxLength: 120,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Password:',
      inputWrapRef: React.createRef(),
    },
    password2: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: '',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
        minLength: 6,
        maxLength: 120,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Confirm password:',
      // matched: true
      matchError: null,
      inputWrapRef: React.createRef(),
    },
    // -- INDUSTRY MOVED TO STEP 2
    // industry: {
    //   elementType: 'select',
    //   elementConfig: {
    //     options: [
    //       // { value: '', label: 'Select Industry' },
    //       { value: 'Real Estate', label: 'Real Estate' },
    //       { value: 'Real Estate Investor', label: 'Real Estate Investor' },
    //       { value: 'Insurance', label: 'Insurance' },
    //       { value: 'Financial Services', label: 'Financial Services' },
    //       { value: 'Mortgage', label: 'Mortgage' },
    //       {
    //         value: 'Advertising/Marketing',
    //         label: 'Advertising/Marketing',
    //       },
    //       { value: 'Other', label: 'Other' },
    //     ],
    //     placeholder: 'Select Industry',
    //     autoComplete: 'off',
    //   },
    //   value: '',
    //   validation: {
    //     required: true,
    //   },
    //   valid: false,
    //   error: '',
    //   touched: false,
    //   label: 'Industry:',
    //   inputWrapRef: React.createRef(),
    // },
    time_zone: {
      elementType: 'select',
      elementConfig: {
        options: [
          { value: 'US/Eastern', label: 'EST - Eastern Standard Time' },
          { value: 'US/Central', label: 'CST - Central Standard Time' },
          { value: 'US/Mountain', label: 'MST - Mountain Standard Time' },
          { value: 'US/Pacific', label: 'PST - Pacific Standard Time' },
        ],
        autoComplete: 'off',
      },
      value: 'US/Eastern',
      validation: {
        required: true,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Time Zone:',
      inputWrapRef: React.createRef(),
    },
    first_security_question: {
      elementType: 'select',
      elementConfig: {
        options: [
          // { value: '', label: 'Select Question' },
          { value: '0', label: 'What city were you born?' },
          { value: '1', label: 'What is your favorite sports team?' },
          { value: '2', label: 'What is the first name of your best friend?' },
        ],
        placeholder: 'Select Question',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Security Question 1:',
      inputWrapRef: React.createRef(),
    },
    first_question_answer: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: '',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
        maxLength: 255,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Answer 1:',
      inputWrapRef: React.createRef(),
    },
    second_security_question: {
      elementType: 'select',
      elementConfig: {
        options: [
          // { value: '', label: 'Select Question' },
          { value: '0', label: 'What is the maiden name of your mother?' },
          { value: '1', label: 'What was your first job?' },
          {
            value: '2',
            label: 'What is the name of the high school you attended?',
          },
          { value: '3', label: 'What year were you married?' },
        ],
        placeholder: 'Select Question',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Security Question 2:',
      inputWrapRef: React.createRef(),
    },
    second_question_answer: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: '',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
        maxLength: 255,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Answer 2:',
      inputWrapRef: React.createRef(),
    },
  };
  const inputSchemeKeys = Object.keys(inputScheme);
  const [infoForm, setInfoForm] = useState(inputScheme);
  const [nonFieldErrors, setNonFieldErrors] = useState(false);
  const [checkboxesRequiredError, setCheckboxesRequiredError] = useState(null);

  useEffect(() => {
    if (stepNumber !== 4) {
      onSetStep(4);
    }
  }, [onSetStep, stepNumber]);

  // Scroll screen to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isCheckboxChecked = (updatedInputScheme) => {
    let checked = false;
    Object.values(
      checkboxesFormFirstColKeys.concat(checkboxesFormSecondColKeys)
    ).forEach((inputIdentifier) => {
      if (updatedInputScheme[inputIdentifier].value && !checked) {
        checked = true;
      }
    });
    return checked;
  };

  const inputChangedHandler = (inputValue, inputIdentifier) => {
    const validationInfo = checkValidity(
      inputValue,
      infoForm[inputIdentifier].validation
    );
    const updatedFormElement = updateObject(infoForm[inputIdentifier], {
      value: inputValue,
      valid: validationInfo.isValid,
      error: validationInfo.error,
      touched: true,
    });

    let updatedForm = updateObject(infoForm, {
      [inputIdentifier]: updatedFormElement,
    });

    // 'other' checkbox click handling.
    let updatedOtherElement = null;
    if (inputIdentifier === 'other_checkbox') {
      switch (inputValue) {
        case true:
          updatedOtherElement = updateObject(updatedForm.other, {
            value: '',
            validation: {
              required: true,
            },
            valid: false,
            error: '',
            touched: false,
          });
          break;
        case false:
          updatedOtherElement = updateObject(updatedForm.other, {
            value: '',
            validation: {
              required: false,
            },
            valid: true,
            error: '',
            touched: false,
          });
          break;
        default:
          updatedOtherElement = null;
      }
      updatedForm = updateObject(updatedForm, {
        other: updatedOtherElement,
      });
    }

    // clear passwords matching error
    if (inputIdentifier === 'password2' || inputIdentifier === 'password') {
      if (updatedForm.password.value === updatedForm.password2.value) {
        const updatedConfirmPasswordElement = updateObject(
          updatedForm.password2,
          {
            matchError: null,
          }
        );
        updatedForm = updateObject(updatedForm, {
          password2: updatedConfirmPasswordElement,
        });
      }
    }

    // clear checkboxes required error
    if (
      checkboxesFormFirstColKeys
        .concat(checkboxesFormSecondColKeys)
        .includes(inputIdentifier)
    ) {
      if (isCheckboxChecked(updatedForm)) setCheckboxesRequiredError(null);
    }

    setInfoForm(updatedForm);
  };

  const checkFormValidity = () => {
    let isValid = true;
    let updatedForm = { ...infoForm };
    Object.keys(infoForm).forEach((inputIdentifier) => {
      const validationInfo = checkValidity(
        infoForm[inputIdentifier].value,
        infoForm[inputIdentifier].validation
      );
      const updatedFormElement = updateObject(infoForm[inputIdentifier], {
        value: infoForm[inputIdentifier].value,
        valid: validationInfo.isValid,
        error: validationInfo.error,
        touched: true,
      });
      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedFormElement,
      });
      isValid = updatedForm[inputIdentifier].valid && isValid;
    });

    // passwords matching check
    let matchError = null;
    if (updatedForm.password2.valid && updatedForm.password.valid) {
      matchError =
        updatedForm.password.value === updatedForm.password2.value
          ? null
          : matchErrorMsg;
    }
    const updatedConfirmPasswordElement = updateObject(updatedForm.password2, {
      matchError,
    });
    updatedForm = updateObject(updatedForm, {
      password2: updatedConfirmPasswordElement,
    });
    isValid = updatedForm.password2.matchError === null && isValid;

    // checkboxes required error
    const checkboxCecked = isCheckboxChecked(updatedForm);
    isValid = checkboxCecked && isValid;

    return { isValid, updatedForm, checkboxCecked };
  };

  const syncDataToRedux = useCallback(() => {
    const formData = {};
    Object.keys(infoForm).forEach((controlName) => {
      formData[controlName] = infoForm[controlName].value;
    });
    onUpdateSignUpData(formData);
  }, [onUpdateSignUpData, infoForm]);

  const prefillFormValues = (prefillData) => {
    setInfoForm((previousInfoForm) => {
      let updatedForm = { ...previousInfoForm };
      Object.keys(previousInfoForm).forEach((controlName) => {
        if (prefillData[controlName]) {
          const updatedFormElement = updateObject(
            previousInfoForm[controlName],
            {
              value: prefillData[controlName],
            }
          );
          updatedForm = updateObject(updatedForm, {
            [controlName]: updatedFormElement,
          });
        }
      });
      return {
        ...previousInfoForm,
        ...updatedForm,
      };
    });
  };

  const prefillFormErrors = (prefillData) => {
    setInfoForm((previousInfoForm) => {
      let updatedForm = { ...previousInfoForm };
      Object.keys(previousInfoForm).forEach((controlName) => {
        if (prefillData[controlName]) {
          const updatedFormElement = updateObject(
            previousInfoForm[controlName],
            {
              error: prefillData[controlName],
              valid: false,
              touched: true,
            }
          );
          updatedForm = updateObject(updatedForm, {
            [controlName]: updatedFormElement,
          });
        }
      });
      return {
        ...previousInfoForm,
        ...updatedForm,
      };
    });
    if ('__all__' in prefillData) {
      // eslint-disable-next-line no-underscore-dangle
      setNonFieldErrors(prefillData.__all__);
    }
  };

  const getHighestErrorField = (updatedInputScheme) => {
    let highestField = null;
    Object.keys(updatedInputScheme).forEach((controlName) => {
      if (
        !updatedInputScheme[controlName].valid &&
        updatedInputScheme[controlName].inputWrapRef
      ) {
        if (!highestField) {
          highestField = updatedInputScheme[controlName];
        } else if (
          updatedInputScheme[controlName].inputWrapRef.current &&
          updatedInputScheme[controlName].inputWrapRef.current.offsetTop <
            highestField.inputWrapRef.current.offsetTop
        ) {
          highestField = updatedInputScheme[controlName];
        }
      }
    });

    // custom password matching error ref check
    if (
      updatedInputScheme.password2 &&
      updatedInputScheme.password2.matchError !== null &&
      updatedInputScheme.password2.inputWrapRef
    ) {
      if (!highestField) {
        highestField = updatedInputScheme.password2;
      } else if (
        updatedInputScheme.password2.inputWrapRef.current &&
        updatedInputScheme.password2.inputWrapRef.current.offsetTop <
          highestField.inputWrapRef.current.offsetTop
      ) {
        highestField = updatedInputScheme.password2;
      }
    }

    // custom checkboxes required error ref check (used 'broker' field inputWrapRef)
    if (
      updatedInputScheme.broker &&
      updatedInputScheme.broker.inputWrapRef &&
      !isCheckboxChecked(updatedInputScheme)
    ) {
      if (!highestField) {
        highestField = updatedInputScheme.broker;
      } else if (
        updatedInputScheme.broker.inputWrapRef.current &&
        updatedInputScheme.broker.inputWrapRef.current.offsetTop <
          highestField.inputWrapRef.current.offsetTop
      ) {
        highestField = updatedInputScheme.broker;
      }
    }
    return highestField;
  };

  useEffect(
    () => {
      if (signUpData) {
        prefillFormValues(signUpData);
      }
      if (customerFormErrors) {
        // testing returned sign-up errors.
        prefillFormErrors(customerFormErrors);

        const highestControl = getHighestErrorField(customerFormErrors);
        if (highestControl) {
          window.scrollTo({
            behavior: 'smooth',
            top: highestControl.inputWrapRef.current.offsetTop - 100,
          });
        }
      }
    },
    // eslint-disable-next-line
    [signUpData, customerFormErrors]
  );

  const formSubmittedHandler = (event) => {
    event.preventDefault();
    const formValidationInfo = checkFormValidity();
    if (!formValidationInfo.isValid) {
      setInfoForm(formValidationInfo.updatedForm);
      setCheckboxesRequiredError(!formValidationInfo.checkboxCecked);

      const highestControl = getHighestErrorField(
        formValidationInfo.updatedForm
      );
      if (highestControl) {
        window.scrollTo({
          behavior: 'smooth',
          top: highestControl.inputWrapRef.current.offsetTop - 100,
        });
      }
    } else {
      syncDataToRedux();
      onSetStepCompleted(4);
      sendGAEvent('Customer and Account Information -> Billing Information');
      history.push('/sign-up/step-5');
    }
  };

  let redirect = null;
  if (stepCompleted === null || stepCompleted < 3) {
    redirect = <Redirect to="/sign-up/step-1" />;
  }

  const infoFormElementsArray = Object.values(inputSchemeKeys).map((key) => {
    return {
      id: key,
      config: infoForm[key],
    };
  });

  return (
    <div className={styles.Step}>
      {redirect}
      <div className={styles.Title}>Customer Information:</div>
      <form autoComplete="off" onSubmit={formSubmittedHandler}>
        {infoFormElementsArray
          .filter((formEl) => customerInfoFormKeys.includes(formEl.id))
          .map((formElement) => {
            if (
              formElement.config.elementType === 'input' &&
              (formElement.id === 'mobile_phone' || formElement.id === 'phone')
            ) {
              return (
                <PhoneInput
                  key={formElement.id}
                  name={formElement.id}
                  label={formElement.config.label}
                  value={formElement.config.value}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={(event) =>
                    inputChangedHandler(event.target.rawValue, formElement.id)
                  }
                  error={formElement.config.error}
                  inputLabelStyle={{ width: '220px' }}
                  autoComplete="off"
                  refProp={formElement.config.inputWrapRef}
                />
              );
            }
            if (formElement.config.elementType === 'input') {
              return (
                <Input
                  key={formElement.id}
                  name={formElement.id}
                  label={formElement.config.label}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={(event) =>
                    inputChangedHandler(event.target.value, formElement.id)
                  }
                  error={formElement.config.error}
                  inputLabelStyle={{ width: '220px' }}
                  inputWrapRef={formElement.config.inputWrapRef}
                />
              );
            }
            if (
              formElement.config.elementType === 'select' &&
              formElement.id === 'state'
            ) {
              return (
                <SelectInput
                  key={formElement.id}
                  name={formElement.id}
                  label={formElement.config.label}
                  value={
                    states && getSelectedState(states, formElement.config.value)
                      ? getSelectedState(states, formElement.config.value)
                      : null
                  }
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={(option) =>
                    inputChangedHandler(option.value, formElement.id)
                  }
                  error={formElement.config.error}
                  inputLabelStyle={{ width: '220px' }}
                  options={states}
                  placeholder={formElement.config.elementConfig.placeholder}
                  autoComplete="off"
                  refProp={formElement.config.inputWrapRef}
                />
              );
            }
            return null;
          })}

        <div className={styles.CheckboxesBlock}>
          <div className={styles.Label}>How did you hear about us?</div>
          <div style={{ flexGrow: '1' }}>
            {infoFormElementsArray
              .filter((formEl) =>
                checkboxesFormFirstColKeys.includes(formEl.id)
              )
              .map((formElement) => {
                return (
                  <div className={styles.CheckboxRow} key={formElement.id}>
                    <Checkbox
                      name={formElement.id}
                      isLarge
                      isChecked={formElement.config.value}
                      changed={(event) =>
                        inputChangedHandler(
                          event.target.checked,
                          formElement.id
                        )
                      }
                      autoComplete="off"
                      refProp={formElement.config.inputWrapRef}
                    >
                      <span>{formElement.config.label}</span>
                    </Checkbox>
                  </div>
                );
              })}
          </div>
          <div>
            {infoFormElementsArray
              .filter((formEl) =>
                checkboxesFormSecondColKeys.includes(formEl.id)
              )
              .map((formElement) => {
                return (
                  <div className={styles.CheckboxRow} key={formElement.id}>
                    <Checkbox
                      name={formElement.id}
                      isLarge
                      isChecked={formElement.config.value}
                      changed={(event) =>
                        inputChangedHandler(
                          event.target.checked,
                          formElement.id
                        )
                      }
                      autoComplete="off"
                      refProp={formElement.config.inputWrapRef}
                    >
                      <span>{formElement.config.label}</span>
                    </Checkbox>
                  </div>
                );
              })}
            {infoForm.other_checkbox.value && (
              <Input
                key="other"
                name="other"
                elementType={infoForm.other.elementType}
                elementConfig={infoForm.other.elementConfig}
                value={infoForm.other.value}
                invalid={!infoForm.other.valid}
                shouldValidate={infoForm.other.validation}
                touched={infoForm.other.touched}
                changed={(event) =>
                  inputChangedHandler(event.target.value, 'other')
                }
                error={infoForm.other.error}
                inputLabelStyle={{ display: 'none' }}
                inputFieldWrapperStyle={{ width: '100%' }}
                inputWrapRef={infoForm.other.inputWrapRef}
              />
            )}
          </div>
          {checkboxesRequiredError && (
            <div className={styles.CheckboxesBlockError}>
              Please, choose at least one option
            </div>
          )}
        </div>

        <div className={styles.Title} style={{ marginTop: '30px' }}>
          Account Information:
        </div>

        {infoFormElementsArray
          .filter((formEl) => accInfoFormKeys.includes(formEl.id))
          .map((formElement) => {
            if (formElement.config.elementType === 'input') {
              let error = '';
              if (formElement.config.error) {
                error = formElement.config.error;
              } else if (formElement.config.matchError) {
                error = formElement.config.matchError;
              }
              return (
                <Input
                  key={formElement.id}
                  name={formElement.id}
                  label={formElement.config.label}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  invalid={
                    !formElement.config.valid || formElement.config.matchError
                  }
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={(event) =>
                    inputChangedHandler(event.target.value, formElement.id)
                  }
                  error={error}
                  inputLabelStyle={{ width: '220px' }}
                  inputWrapRef={formElement.config.inputWrapRef}
                />
              );
            }
            if (formElement.config.elementType === 'select') {
              return (
                <SelectInput
                  key={formElement.id}
                  name={formElement.id}
                  label={formElement.config.label}
                  value={getSelectedOption(
                    formElement.config.elementConfig.options,
                    formElement.config.value
                  )}
                  invalid={!formElement.config.valid}
                  shouldValidate={formElement.config.validation}
                  touched={formElement.config.touched}
                  changed={(option) =>
                    inputChangedHandler(option.value, formElement.id)
                  }
                  error={formElement.config.error}
                  inputLabelStyle={{ width: '220px' }}
                  options={formElement.config.elementConfig.options}
                  placeholder={formElement.config.elementConfig.placeholder}
                  defaultValue={formElement.config.elementConfig.options[0]}
                  autoComplete="off"
                  refProp={formElement.config.inputWrapRef}
                />
              );
            }
            return null;
          })}

        <div className={styles.ButtonsWrap}>
          <Button
            onClick={() => {
              syncDataToRedux();
              sendGAEvent(
                `${renameGAEvent(accountType)} ${renameGAEvent(
                  dataAndDialerChoice
                )} <- Customer and Account Information`
              );
              history.push('/sign-up/step-3');
            }}
            isBtnPrev
            style={{ width: '146px' }}
          >
            Previous
          </Button>
          <Button type="submit" isBtnNext style={{ width: '146px' }}>
            Next
          </Button>
        </div>

        {nonFieldErrors && (
          <div className={styles.NonFieldErrors}>{nonFieldErrors}</div>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stepNumber: state.signUp.stepNumber,
    stepCompleted: state.signUp.stepCompleted,
    signUpData: state.signUp.data,
    totalPrice: state.signUp.totalPrice,
    states: state.signUp.states,
    // tempSignUpData: state.signUp.tempData,
    formErrors: state.signUp.formErrors,
    accountType: state.signUp.accountType,
    dataAndDialerChoice: state.signUp.dataAndDialerChoice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetStep: (stepNum) => dispatch(actions.setStepNumber(stepNum)),
    onSetStepCompleted: (stepNum) =>
      dispatch(actions.setStepCompleted(stepNum)),
    onUpdateSignUpData: (signUpdata) =>
      dispatch(actions.updateSignUpData(signUpdata)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepFour);
