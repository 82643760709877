import ax from './axios';
import { formDataFromParams } from './utils';

export const authorize = (data) => {
  return ax({
    url: `/rest/oauth/authorize/`,
    method: 'post',
    data: formDataFromParams(data),
    config: { headers: { 'Content-Type': 'application/json' } },
  });
};

export const authorizeZapier = (data) => {
  return ax({
    url: `/rest/oauth/authorize-zapier/`,
    method: 'post',
    data: formDataFromParams(data),
    config: { headers: { 'Content-Type': 'application/json' } },
  });
};
