import { getSignUpContext } from '../../api/SignUp';
import * as actionTypes from './actionTypes';

export const setStepNumber = (number) => {
  return {
    type: actionTypes.SET_STEP_NUMBER,
    stepNumber: number,
  };
};

export const setStepCompleted = (number) => {
  return {
    type: actionTypes.SET_STEP_COMPLETED,
    stepNumber: number,
  };
};

export const setAccountEmail = (email) => {
  return {
    type: actionTypes.SET_EMAIL,
    emailValue: email,
  };
};

export const setAccountType = (accType) => {
  return {
    type: actionTypes.SET_ACCOUNT_TYPE,
    accountType: accType,
  };
};

export const setDataAndDialerChoice = (choice) => {
  return {
    type: actionTypes.SET_DATA_AND_DIALER_CHOICE,
    dataAndDialerChoice: choice,
  };
};

export const setContext = (prices, misc, countries, states) => {
  return {
    type: actionTypes.SET_CONTEXT,
    prices,
    misc,
    countries,
    states,
  };
};

export const fetchContextFailed = (error) => {
  return {
    type: actionTypes.FETCH_CONTEXT_FAILED,
    error,
  };
};

export const signUpFailed = (error) => {
  return {
    type: actionTypes.SIGNUP_FAILED,
    error,
  };
};

export const initContext = () => {
  return (dispatch) => {
    getSignUpContext()
      .then((response) => {
        dispatch(
          setContext(
            response.data.prices,
            response.data.misc,
            response.data.countries,
            response.data.states
          )
        );
      })
      .catch((err) => {
        dispatch(fetchContextFailed(err.response.data.error));
      });
  };
};

export const updateSignUpData = (data) => {
  return {
    type: actionTypes.UPDATE_SIGNUP_DATA,
    data,
  };
};
