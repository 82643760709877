import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './CancellationFeedback.module.scss';

export default function CancellationFeedback() {
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const statusSearchParam = searchParams.get('pin');

  const origin =
    window.location.hostname === 'lb11.mojosells.com'
      ? 'https://lb11.mojosells.com'
      : 'https://devlb.mojosells.com';

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        {statusSearchParam ? (
          <iframe
            title="cancellation_feedback"
            src={`${origin}/cancellation_feedback/?pin=${statusSearchParam}`}
            style={{
              border: 'none',
              width: '800px',
              height: '1000px',
            }}
          />
        ) : (
          <p>Page does not exist</p>
        )}
      </div>
    </div>
  );
}
