import React, { useState, useCallback, useEffect } from 'react';

import Spinner from '../../components/Spinner/Spinner';
import * as api from '../../api/SMTPSettingsConfirmation';
import styles from './SMTPSettingsConfirmation.module.scss';

const SMTPSettingsConfirmation = (props) => {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(null);
  const { match } = props;
  const { token } = match.params;

  const confirmEmail = useCallback(async (token) => {
    setPageIsLoading(true);
    try {
      await api.confirmSMTPSettings(token);
      setIsConfirmed(true);
    } catch (e) {
      setIsConfirmed(false);
      if (e.response) {
        setError(e.response.data.error);
      } else {
        setError(e.message);
      }
    }
    setPageIsLoading(false);
  }, []);

  useEffect(() => {
    confirmEmail(token);
  }, [token, confirmEmail]);

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        {pageIsLoading ? (
          <Spinner />
        ) : (
          <>
            <div className={styles.title}>SMTP email confirmation</div>
            {isConfirmed ? (
              <div className={styles.msg}>
                <div className={styles.success}>
                  <div className={styles.title}>Success!</div>
                  <div className={styles.content}>
                    Your SMTP settings were successfully verified.
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.msg}>
                <div className={styles.error}>
                  <div className={styles.title}>Error!</div>
                  <div className={styles.content}>{error}</div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SMTPSettingsConfirmation;
