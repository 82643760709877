import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import styles from './SignUpProgress.module.scss';
import signInImg from '../../../assets/images/signin-img.svg';
import signUpImg from '../../../assets/images/signup-img.svg';
import passwordResetImg from '../../../assets/images/password-reset-img.svg';

const SignUpProgress = (props) => {
  const [content, setContent] = useState(null);
  const location = useLocation();
  const { currentStep } = props;

  const getStepClasses = useCallback(
    (stepNumber) => {
      const stepClasses = [styles.SignUpProgressStep];
      if (stepNumber < currentStep) {
        stepClasses.push(styles.SignUpProgressStepPassed);
      } else if (stepNumber === currentStep) {
        stepClasses.push(styles.SignUpProgressStepActive);
      }
      return stepClasses;
    },
    [currentStep]
  );

  const getContent = useCallback(
    (localLocation) => {
      if (localLocation.pathname.includes('sign-up')) {
        setContent(
          <>
            <div className={styles.SignUpProgressSteps}>
              <div className={getStepClasses(1).join(' ')}>
                Step 1: Enter email
              </div>
              <div className={getStepClasses(2).join(' ')}>
                Step 2: Subscription
              </div>
              <div className={getStepClasses(3).join(' ')}>
                Step 3: Services
              </div>
              <div className={getStepClasses(4).join(' ')}>
                Step 4: Information
              </div>
              <div className={getStepClasses(5).join(' ')}>Step 5: Billing</div>
            </div>
            <div className={styles.SignUpProgressImage}>
              <img src={signUpImg} alt="" />
            </div>
          </>
        );
      } else if (
        localLocation.pathname.includes('login') ||
        localLocation.pathname.includes('oauth') ||
        localLocation.pathname.includes('browser_select')
      ) {
        setContent(
          <>
            <div className={styles.SignUpProgressTitle}>Welcome to Mojo</div>
            <div className={styles.SignUpProgressImage}>
              <img src={signInImg} alt="" style={{ maxWidth: '100%' }} />
            </div>
          </>
        );
      } else if (localLocation.pathname.includes('password-reset')) {
        setContent(
          <>
            <div className={styles.SignUpProgressTitle}>Welcome to Mojo</div>
            <div
              className={styles.SignUpProgressImage}
              style={{ marginTop: '60px' }}
            >
              <img src={passwordResetImg} alt="" />
            </div>
          </>
        );
      } else if (
        localLocation.pathname.includes('/integrations/bombbomb/redirect_uri/')
      ) {
        setContent(
          <>
            <div className={styles.SignUpProgressTitle}>Integration</div>
            <div
              className={styles.SignUpProgressImage}
              style={{ marginTop: '60px' }}
            >
              <img src={passwordResetImg} alt="" />
            </div>
          </>
        );
      }
    },
    // eslint-disable-next-line
    [getStepClasses, currentStep]
  );

  useEffect(() => {
    getContent(location);
  }, [getContent, location]);

  return <div className={styles.SignUpProgress}>{content}</div>;
};

const mapStateToProps = (state) => {
  return {
    currentStep: state.signUp.stepNumber,
  };
};

export default connect(mapStateToProps)(SignUpProgress);
