import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import CleaveInput from 'cleave.js/react';
import styles from './StepFive.module.scss';
import Select from '../../../../components/UI/Select/Select';
import HelpPopover from '../../../../components/Shared/HelpPopover/HelpPopover';
import Checkbox from '../../../../components/UI/Inputs/Checkbox/Checkbox';
import Price from '../../../../components/SignUp/Price/Price';
import Button from '../../../../components/UI/Button/Button';
import * as actions from '../../../../redux/actions/index';
import { checkValidity, stringToJsx } from '../../../../shared/utility';
import { detectIE } from '../../../../utils/utils';
import * as api from '../../../../api/SignUp';
import axios from '../../../../api/axios';
import useImportScript from '../../../../hooks/useImportScript';

let requestSource = null;

const getOpts = (start, count) => {
  const opts = [];
  for (let i = 0; i < count; i += 1) {
    opts.push({ value: start + i, label: start + i });
  }
  return opts;
};

const getYearOpts = (count) => {
  return getOpts(new Date().getFullYear(), count);
};

const getSelectedState = (states, value) => {
  let result = null;
  Object.values(states).forEach((data) => {
    Object.values(data.options).forEach((stateOption) => {
      if (stateOption.value === value) {
        result = stateOption;
      }
    });
  });
  return result;
};

const setupErrors = (errors) => {
  const updatedErrors = { ...errors };
  Object.keys(updatedErrors).forEach((groupName) => {
    Object.keys(updatedErrors[groupName]).forEach((fieldName) => {
      updatedErrors[groupName][fieldName] =
        updatedErrors[groupName][fieldName].join(' ');
    });
  });
  return updatedErrors;
};

const StepFive = (props) => {
  const {
    onSetStep,
    onUpdateSignUpData,
    onSetStepCompleted,
    onSignUpFailed,
    sendGAEvent,
    sendGAConversion,
    stepNumber,
    stepCompleted,
    signUpData,
    countries,
    states,
    totalPrice,
    formErrors,
  } = props;

  const sendSEONData = useCallback(() => {
    const uniqueId =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    window.seon.config({
      session_id: uniqueId,
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      onSuccess() {
        window.seon.getBase64Session((data) => {
          if (data) {
            setSEONPayload(data);
            console.log('Session payload', data);
          }
        });
      },
    });
  }, []);

  // Import external script
  useImportScript('https://cdn.seon.io/js/v4/agent.js', sendSEONData);

  const [SEONPayload, setSEONPayload] = useState('');
  const expDateMonthOpts = getOpts(1, 12);
  const expDateYearOpts = getYearOpts(15);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [nonFieldErrors, setNonFieldErrors] = useState(false);
  const [localFormErrors, setLocalFormErrors] = useState({});
  const [inputScheme, setInputScheme] = useState({
    number: {
      error: null,
      value: '',
      touched: false,
      is_valid: false,
      validation: {
        required: true,
        minLength: 14,
      },
      ref: React.createRef(),
    },
    holder: {
      error: null,
      value: '',
      touched: false,
      is_valid: false,
      validation: {
        required: true,
      },
      ref: React.createRef(),
    },
    expiration_date: {
      error: null,
      value: null,
      touched: false,
      is_valid: false,
      ref: React.createRef(),
    },
    expiration_date_0: {
      error: null,
      value: expDateMonthOpts[0].value,
      touched: false,
      is_valid: false,
    },
    expiration_date_1: {
      error: null,
      value: expDateYearOpts[0].value,
      touched: false,
      is_valid: false,
    },
    cvv: {
      error: null,
      value: '',
      touched: false,
      is_valid: false,
      validation: {
        required: true,
        minLength: 3,
      },
      ref: React.createRef(),
    },
    country_code_alpha2: {
      error: null,
      value: countries ? countries[0].value : null,
      touched: false,
      is_valid: false,
      ref: React.createRef(),
    },
    street_address: {
      error: null,
      value: '',
      touched: false,
      is_valid: false,
      validation: {
        required: true,
        maxLength: 60,
      },
      ref: React.createRef(),
    },
    locality: {
      error: null,
      value: '',
      touched: false,
      is_valid: false,
      validation: {
        required: true,
        maxLength: 60,
      },
      ref: React.createRef(),
    },
    region: {
      error: null,
      value:
        states && getSelectedState(states, 'Alabama')
          ? getSelectedState(states, 'Alabama').value
          : null,
      touched: false,
      is_valid: false,
      validation: {
        required: true,
      },
      ref: React.createRef(),
    },
    postal_code: {
      error: null,
      value: '',
      touched: false,
      is_valid: false,
      validation: {
        required: true,
        maxLength: 32,
        isPostalCode: true,
      },
      ref: React.createRef(),
    },
    terms_and_conditions: {
      error: null,
      value: false,
      touched: false,
      is_valid: false,
      validation: {
        required: true,
        isBoolean: true,
      },
      ref: React.createRef(),
    },
  });

  const onChangeHandler = (value, controlName) => {
    const validationResult = checkValidity(
      value,
      inputScheme[controlName].validation
    );
    setInputScheme((prevInputsScheme) => {
      return {
        ...prevInputsScheme,
        [controlName]: {
          ...prevInputsScheme[controlName],
          value,
          is_valid: validationResult.isValid,
          error: validationResult.error,
          touched: true,
        },
      };
    });
  };

  const validateAllFields = () => {
    let formIsValid = true;
    const newInputScheme = {};
    Object.keys(inputScheme).forEach((key) => {
      const validationResult = checkValidity(
        inputScheme[key].value,
        inputScheme[key].validation
      );

      newInputScheme[key] = {
        ...inputScheme[key],
        is_valid: validationResult.isValid,
        error: validationResult.error,
        touched: true,
      };

      if (!validationResult.isValid) {
        formIsValid = false;
      }
    });
    setInputScheme(newInputScheme);
    return { isValid: formIsValid, newInputScheme };
  };

  const getFieldError = useCallback(
    (controlName, action) => {
      let errorsDiv = <div className={styles.InputError} />;
      let result = null;

      const spanClasses = [styles.InputSpan];
      const inputClasses = [styles.Input];

      if (
        inputScheme[controlName] &&
        inputScheme[controlName].touched &&
        !inputScheme[controlName].is_valid
      ) {
        errorsDiv = (
          <div className={styles.InputError} ref={inputScheme[controlName].ref}>
            {controlName === 'terms_and_conditions' ? (
              <span style={{ fontSize: '14px' }}>
                Please accept terms and conditions.
              </span>
            ) : (
              inputScheme[controlName].error
            )}
          </div>
        );
        spanClasses.push(styles.InputSpanInvalid);
        inputClasses.push(styles.InputInvalid);
      }

      if (action === 'errorsDiv') {
        result = errorsDiv;
      } else if (action === 'spanClasses') {
        result = spanClasses;
      } else if (action === 'inputClasses') {
        result = inputClasses;
      }

      return result;
    },
    [inputScheme]
  );

  const prefillValueFromRedux = useCallback(
    (prefillData) => {
      // Update state by redux prefill data
      const newInputScheme = { ...inputScheme };
      Object.keys(inputScheme).forEach((controlName) => {
        if (controlName in prefillData && prefillData[controlName]) {
          newInputScheme[controlName].value = prefillData[controlName];
        }
      });
      setInputScheme(newInputScheme);
    },
    [inputScheme]
  );

  const prefillErrorFromRedux = useCallback(
    (prefillData) => {
      // Update state by redux prefill data
      const newInputScheme = { ...inputScheme };
      Object.keys(inputScheme).forEach((controlName) => {
        if (controlName in prefillData) {
          newInputScheme[controlName].error = prefillData[controlName];
          newInputScheme[controlName].touched = true;
          newInputScheme[controlName].is_valid = false;
        }
      });
      setInputScheme(newInputScheme);
      if ('__all__' in prefillData) {
        // eslint-disable-next-line no-underscore-dangle
        setNonFieldErrors(stringToJsx(prefillData.__all__));
      }
    },
    [inputScheme]
  );

  const toValidGoogleAdPhoneNumber = (value) => {
    const updatedValue = value
      .replace('-', '')
      .replace(' ', '')
      .replace('(', '')
      .replace(')', '')
      .trim();

    // add "+1" according to E.164 format as `google ad` requires
    return `+1${updatedValue}`;
  };

  const onSignUpHandler = () => {
    setIsLoading(true);
    const formValidated = validateAllFields();
    if (formValidated.isValid) {
      // Sync data to redux
      onUpdateSignUpData(getInputSchemeValues());

      if (requestSource) {
        requestSource.cancel();
      }

      requestSource = axios.CancelToken.source();

      api
        .signUp(
          { ...signUpData, ...getInputSchemeValues(), SEONPayload },
          requestSource.token
        )
        .then(() => {
          setIsLoading(false);
          onSetStepCompleted(5);
          sendGAEvent('Sign Up');

          const phones = [toValidGoogleAdPhoneNumber(signUpData.mobile_phone)];
          if (
            signUpData.phone &&
            signUpData.phone !== undefined &&
            signUpData.phone !== null &&
            signUpData.phone !== ''
          ) {
            phones.push(toValidGoogleAdPhoneNumber(signUpData.phone));
          }
          sendGAConversion(
            signUpData.email,
            phones,
            signUpData.first_name,
            signUpData.last_name,
            signUpData.address,
            signUpData.city,
            signUpData.state,
            countries.find(
              (option) => option.value === inputScheme.country_code_alpha2.value
            ).value,
            signUpData.zip_code,
            totalPrice
          );

          if (detectIE()) {
            history.push('/browser_select/?status=registered');
          } else {
            history.push('/login/?status=registered');
          }
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setIsLoading(false);
            if (err.response) {
              if (err.response.data.errors instanceof Object) {
                onSignUpFailed(setupErrors(err.response.data.errors));

                if (
                  err.response.data.errors.emails_form &&
                  Object.keys(err.response.data.errors.emails_form).length > 0
                ) {
                  history.push('/sign-up/step-1');
                  onSetStepCompleted(0);
                } else if (
                  err.response.data.errors.sign_up_form &&
                  Object.keys(err.response.data.errors.sign_up_form).length > 0
                ) {
                  history.push('/sign-up/step-3');
                  onSetStepCompleted(2);
                } else if (
                  err.response.data.errors.customer_form &&
                  Object.keys(err.response.data.errors.customer_form).length > 0
                ) {
                  history.push('/sign-up/step-4');
                  onSetStepCompleted(3);
                } else if (
                  err.response.data.errors.payment_form &&
                  Object.keys(err.response.data.errors.payment_form).length > 0
                ) {
                  history.push('/sign-up/step-5');
                  onSetStepCompleted(4);
                }
              } else if (
                typeof err.response.data.errors === 'string' ||
                err.response.data.errors instanceof String
              ) {
                setNonFieldErrors(err.response.data.errors);
              }
            } else {
              setNonFieldErrors(err.message);
            }
          }
          requestSource = null;
        });
    } else {
      const updateLocalFormErrors = {};
      Object.keys(formValidated.newInputScheme).forEach((controlName) => {
        if (!formValidated.newInputScheme[controlName].is_valid)
          updateLocalFormErrors[controlName] =
            formValidated.newInputScheme[controlName].error;
      });

      setLocalFormErrors(updateLocalFormErrors);
      setIsLoading(false);
    }
  };

  const getInputSchemeValues = useCallback(() => {
    const data = {};
    Object.keys(inputScheme).forEach((controlName) => {
      data[controlName] = inputScheme[controlName].value;
    });
    return data;
  }, [inputScheme]);

  useEffect(() => {
    if (stepNumber !== 5) {
      onSetStep(5);
    }
  }, [onSetStep, stepNumber]);

  useEffect(
    () => {
      if (signUpData) {
        prefillValueFromRedux(signUpData);
      }
    },
    // eslint-disable-next-line
    [signUpData]
  );

  // Scroll screen to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(
    () => {
      if (
        formErrors &&
        formErrors.payment_form &&
        Object.keys(formErrors.payment_form).length > 0
      ) {
        prefillErrorFromRedux({
          ...formErrors.payment_form,
        });

        let highestField = null;
        Object.keys(inputScheme).forEach((controlName) => {
          if (
            !inputScheme[controlName].is_valid &&
            inputScheme[controlName].touched &&
            inputScheme[controlName].ref !== undefined &&
            inputScheme[controlName].ref.current
          ) {
            if (!highestField) {
              highestField = controlName;
            } else if (
              inputScheme[controlName].ref.current &&
              inputScheme[controlName].ref.current.offsetTop <
                inputScheme[highestField].ref.current.offsetTop
            ) {
              highestField = controlName;
            }
          }
        });

        if (highestField && inputScheme[highestField].ref.current) {
          window.scrollTo({
            behavior: 'smooth',
            top: inputScheme[highestField].ref.current.offsetTop - 100,
          });
        }
      }
    },
    // eslint-disable-next-line
    [formErrors]
  );

  useEffect(
    () => {
      if (localFormErrors && Object.keys(localFormErrors).length > 0) {
        let highestField = null;
        Object.keys(inputScheme).forEach((controlName) => {
          if (
            !inputScheme[controlName].is_valid &&
            inputScheme[controlName].touched &&
            inputScheme[controlName].ref !== undefined &&
            inputScheme[controlName].ref.current
          ) {
            if (!highestField) {
              highestField = controlName;
            } else if (
              inputScheme[controlName].ref.current &&
              inputScheme[controlName].ref.current.offsetTop <
                inputScheme[highestField].ref.current.offsetTop
            ) {
              highestField = controlName;
            }
          }
        });

        if (highestField && inputScheme[highestField].ref.current) {
          window.scrollTo({
            behavior: 'smooth',
            top: inputScheme[highestField].ref.current.offsetTop - 100,
          });
        }
      }
    },
    // eslint-disable-next-line
    [localFormErrors]
  );

  let redirect = null;
  if (stepCompleted === null || stepCompleted < 4) {
    redirect = <Redirect to="/sign-up/step-1" />;
  }

  return (
    <div className={styles.Step}>
      {redirect}
      <div className={styles.Title}>Billing Information:</div>
      <div className={styles.Row}>
        <div className={styles.Label}>Card Number:</div>
        <div className={styles.InputWrap}>
          <div className={styles.InputWrapInner}>
            <span className={getFieldError('number', 'spanClasses').join(' ')}>
              <CleaveInput
                placeholder="xxxx-xxxx-xxxx-xxxx"
                options={{ creditCard: true, delimiter: '-' }}
                onChange={(event) =>
                  onChangeHandler(event.target.rawValue, 'number')
                }
                className={getFieldError('number', 'inputClasses').join(' ')}
                autoComplete="off"
                value={inputScheme.number.value}
              />
            </span>
            {getFieldError('number', 'errorsDiv')}
          </div>
        </div>
      </div>
      <div className={styles.Row}>
        <div className={styles.Label}>Card Holder Name:</div>
        <div className={styles.InputWrap}>
          <div className={styles.InputWrapInner}>
            <span className={getFieldError('holder', 'spanClasses').join(' ')}>
              <input
                type="text"
                className={getFieldError('holder', 'inputClasses').join(' ')}
                onChange={(event) =>
                  onChangeHandler(event.target.value, 'holder')
                }
                autoComplete="off"
                value={inputScheme.holder.value}
              />
            </span>
            {getFieldError('holder', 'errorsDiv')}
          </div>
        </div>
      </div>
      <div className={styles.Row}>
        <div className={styles.Label}>Expiration Date:</div>
        <div className={styles.InputWrap}>
          <div className={styles.InputWrapInner}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '90px' }}>
                <Select
                  value={expDateMonthOpts.filter(
                    (option) =>
                      option.value === inputScheme.expiration_date_0.value
                  )}
                  options={expDateMonthOpts}
                  isSearchable={false}
                  autoComplete="off"
                  changed={(option) =>
                    onChangeHandler(option.value, 'expiration_date_0')
                  }
                />
              </div>
              /
              <div style={{ width: '90px' }}>
                <Select
                  value={expDateYearOpts.filter(
                    (option) =>
                      option.value === inputScheme.expiration_date_1.value
                  )}
                  options={expDateYearOpts}
                  isSearchable={false}
                  autoComplete="off"
                  changed={(option) =>
                    onChangeHandler(option.value, 'expiration_date_1')
                  }
                />
              </div>
            </div>
            {getFieldError('expiration_date', 'errorsDiv')}
          </div>
        </div>
      </div>
      <div className={styles.Row}>
        <div className={styles.Label}>CVV code:</div>
        <div className={styles.InputWrap}>
          <div className={styles.InputWrapInner}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className={getFieldError('cvv', 'spanClasses').join(' ')}>
                <input
                  type="password"
                  maxLength="4"
                  className={getFieldError('cvv', 'inputClasses').join(' ')}
                  style={{ width: '90px' }}
                  autoComplete="off"
                  onChange={(event) =>
                    onChangeHandler(event.target.value, 'cvv')
                  }
                  value={inputScheme.cvv.value}
                />
              </span>
              <HelpPopover title="CVV Code" style={{ marginLeft: '8px' }}>
                The CCV code is either a 3 or 4 digit unique identifier for your
                credit card. For Visa, MC, Discover, it is a 3 digit code
                located on the back of the card. For American Express, it is the
                4 digit code on the front of the card.
              </HelpPopover>
            </div>
            {getFieldError('cvv', 'errorsDiv')}
          </div>
        </div>
      </div>
      <div className={styles.Row}>
        <div className={styles.Label}>Country:</div>
        <div className={styles.InputWrap}>
          <div style={{ width: '100%' }}>
            <Select
              value={
                countries &&
                countries.filter(
                  (option) =>
                    option.value === inputScheme.country_code_alpha2.value
                )
              }
              options={countries}
              autoComplete="off"
              changed={(option) =>
                onChangeHandler(option.value, 'country_code_alpha2')
              }
            />
          </div>
        </div>
      </div>

      <div className={styles.Row}>
        <div className={styles.Label}>Billing Address:</div>
        <div className={styles.InputWrap}>
          <div className={styles.InputWrapInner}>
            <span
              className={getFieldError('street_address', 'spanClasses').join(
                ' '
              )}
            >
              <input
                type="text"
                className={getFieldError('street_address', 'inputClasses').join(
                  ' '
                )}
                autoComplete="off"
                onChange={(event) =>
                  onChangeHandler(event.target.value, 'street_address')
                }
                value={inputScheme.street_address.value}
              />
            </span>
            {getFieldError('street_address', 'errorsDiv')}
          </div>
        </div>
      </div>

      <div className={styles.Row}>
        <div className={styles.Label}>Billing City:</div>
        <div className={styles.InputWrap}>
          <div className={styles.InputWrapInner}>
            <span
              className={getFieldError('locality', 'spanClasses').join(' ')}
            >
              <input
                type="text"
                className={getFieldError('locality', 'inputClasses').join(' ')}
                autoComplete="off"
                onChange={(event) =>
                  onChangeHandler(event.target.value, 'locality')
                }
                value={inputScheme.locality.value}
              />
            </span>
            {getFieldError('locality', 'errorsDiv')}
          </div>
        </div>
      </div>

      <div className={styles.Row}>
        <div className={styles.Label}>Billing State:</div>
        <div className={styles.InputWrap}>
          <div style={{ width: '300px' }}>
            <Select
              value={
                states && getSelectedState(states, inputScheme.region.value)
                  ? getSelectedState(states, inputScheme.region.value)
                  : null
              }
              options={states}
              autoComplete="off"
              changed={(option) => onChangeHandler(option.value, 'region')}
            />
          </div>
        </div>
      </div>
      <div className={styles.Row}>
        <div className={styles.Label}>Billing Zip Code:</div>
        <div className={styles.InputWrap}>
          <div className={styles.InputWrapInner}>
            <span
              className={getFieldError('postal_code', 'spanClasses').join(' ')}
            >
              <input
                type="text"
                className={getFieldError('postal_code', 'inputClasses').join(
                  ' '
                )}
                onChange={(event) =>
                  onChangeHandler(event.target.value, 'postal_code')
                }
                autoComplete="off"
                value={inputScheme.postal_code.value}
              />
            </span>
            {getFieldError('postal_code', 'errorsDiv')}
          </div>
        </div>
      </div>

      <div className={styles.Row} style={{ width: '500px', marginTop: '37px' }}>
        <div>
          <div>
            <Checkbox
              labelStyle={{ alignItems: 'start' }}
              isLarge
              autoComplete="off"
              changed={(event) =>
                onChangeHandler(event.target.checked, 'terms_and_conditions')
              }
            >
              <div
                style={{
                  fontSize: '16px',
                  color: '#3F4752',
                  lineHeight: '22px',
                }}
              >
                Accept Terms and Conditions (
                <a
                  href="/terms_and_conditions/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  view
                </a>
                ) and agree to a monthly recurring charge from Mojo
              </div>
            </Checkbox>
          </div>
          <div style={{ textAlign: 'center' }}>
            {getFieldError('terms_and_conditions', 'errorsDiv')}
          </div>
        </div>
      </div>

      <div className={styles.SubtotalWrap}>
        <span className={styles.SubtotalLabel}>Total:</span>
        <Price price={totalPrice} />
      </div>

      <div className={styles.ButtonsWrap}>
        <Button
          onClick={() => {
            onUpdateSignUpData(getInputSchemeValues());
            sendGAEvent(
              'Customer and Account Information <- Billing Information'
            );
            history.push('/sign-up/step-4');
          }}
          isBtnPrev
          isLoading={isLoading}
          disabled={isLoading}
          style={{ width: '146px' }}
        >
          Previous
        </Button>
        <Button
          onClick={onSignUpHandler}
          isBtnNext
          isLoading={isLoading}
          disabled={isLoading}
          style={{
            width: '146px',
            backgroundColor: '#45B209',
            borderColor: '#45B209',
            color: '#FFFFFF',
          }}
        >
          PAY NOW
        </Button>
      </div>
      {nonFieldErrors && (
        <div className={styles.NonFieldErrors}>{nonFieldErrors}</div>
      )}

      <div className={styles.GeneralNote}>
        <p className={styles.GeneralNoteTitle}>Recurring Billing:</p>
        <p>
          <b>
            All Services provided by Mojo are paid in advance and are
            non-refundable.{' '}
          </b>
          To provide continuous service, billing for all services recurs monthly
          from the subscription date. Customers can cancel at any time before
          their next billing date and continue to use Mojo through the end of
          their pre-paid month. See our complete{' '}
          <a
            href="/terms_and_conditions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms of service{' '}
          </a>
          here.
        </p>
        <p className={styles.GeneralNoteTitle}>Card Authorization:</p>
        <p>
          It is not uncommon for a request to fail multiple times before it is
          eventually authorized. You will receive the appropriate message should
          your card not be authorized. For check/debit cards, an authorization
          will likely appear on your statement for a short time. This is only an
          authorization by your bank and the funds will be released back in to
          your account.
        </p>
        <p className={styles.GeneralNoteTitle}>Gift Cards:</p>
        <p>
          The purchase made here today is for a recurring subscription payment
          for our Mojo services. As a rule, most gift cards do not allow this
          kind of transaction to be authorized. If you cannot authorize a gift
          card, please use a different form of payment.
        </p>
        <p className={styles.GeneralNoteTitle}>Security:</p>
        <p>
          Our sign up pages and account pages that request personal information
          are secure behind a complex network of encrypted server and firewall
          technology. Your personal information is safe and is not shared with
          any third parties.
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stepNumber: state.signUp.stepNumber,
    stepCompleted: state.signUp.stepCompleted,
    signUpData: state.signUp.data,
    countries: state.signUp.countries,
    states: state.signUp.states,
    totalPrice: state.signUp.totalPrice,
    formErrors: state.signUp.formErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetStep: (stepNum) => dispatch(actions.setStepNumber(stepNum)),
    onUpdateSignUpData: (signUpdata) =>
      dispatch(actions.updateSignUpData(signUpdata)),
    onSignUpFailed: (errors) => dispatch(actions.signUpFailed(errors)),
    onSetStepCompleted: (stepNum) =>
      dispatch(actions.setStepCompleted(stepNum)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepFive);
