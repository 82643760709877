import React from 'react';
import styles from './HeaderMenu.module.css';
import HeaderMenuItem from './HeaderMenuItem/HeaderMenuItem';

export default function HeaderMenu() {
  return (
    <div className={styles.HeaderMenu}>
      <HeaderMenuItem url="https://www.mojosells.com/" name="Home" />
      <HeaderMenuItem url="https://www.mojosells.com/#media" name="Media" />
      <HeaderMenuItem url="https://www.mojosells.com/#pricing" name="Pricing" />
      <HeaderMenuItem url="https://www.mojosells.com/#blog" name="Blog" />
      <HeaderMenuItem url="https://www.mojosells.com/#faq" name="FAQ" />
      <HeaderMenuItem url="https://www.mojosells.com/#about" name="About Us" />
    </div>
  );
}
