import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Prompt from '../../../../components/Shared/Prompt/Prompt';
import Select from '../../../../components/UI/Select/Select';
import Button from '../../../../components/UI/Button/Button';
import Checkbox from '../../../../components/UI/Inputs/Checkbox/Checkbox';
import ChoiceCard from '../../../../components/UI/SignUp/ChoiceCard';
import HelpPopover from '../../../../components/Shared/HelpPopover/HelpPopover';
import Price from '../../../../components/SignUp/Price/Price';

import singleUserIcon from '../../../../assets/images/signup/single-user-icon.svg';
import multiUserIcon from '../../../../assets/images/signup/multi-user-icon.svg';

import styles from './StepThree.module.css';
import * as actions from '../../../../redux/actions/index';

const getOpts = (min, count) => {
  const opts = [];
  for (let i = min; i <= count; i += 1) {
    opts.push({ value: i, label: i });
  }
  return opts;
};

const getHelpPopover = (type, price) => {
  let content = null;
  switch (type.toLowerCase()) {
    case 'hosted_lead':
      content = (
        <HelpPopover title="Mojo CRM" style={{ marginLeft: '14px' }}>
          <p>
            Our Mojo CRM license gives you the keys to our simple, easy to use
            web-based CRM. You can import data, create action plans, send
            emails, print letters, set appointments/reminders and manage your
            pipeline. Each agent on your account will need a Mojo CRM license.
          </p>
          <p>
            Once you&#39;ve selected the number of Mojo CRM logins, you can then
            add your desired dialing licenses. Dialing licenses are separate
            from Mojo CRM licenses. You can have agents share a dialing license
            as long as they aren&#39;t dialing at the same time. Need to have
            agents dialing at the same time? No problem, choose the correct
            number of dialing licenses to meet your needs.
          </p>
        </HelpPopover>
      );
      break;
    case 'single_line':
      content = (
        <HelpPopover
          title="Single Line Power Dialer"
          style={{ marginLeft: '14px' }}
        >
          Our Single line power dialer is perfect for those that want to
          automate their dialing routine and make 3x the amount of calls vs.
          dialing by hand. This option gives you the efficiency of up to 85
          Calls-per hour and the quality of first hello technology and crisp,
          clean conversations.
        </HelpPopover>
      );
      break;
    case 'triple_line':
      content = (
        <HelpPopover
          title="Triple Line Power Dialer"
          style={{ marginLeft: '14px' }}
        >
          Our Triple line power dialer dials your lists at a blazing speed of up
          to 300 Calls per-hour using our industry exclusive copper-based,
          hosted multi –line system. 300 calls per hour will get you more
          contacts than you can imagine while giving you the pipeline to close
          more deals, FAST! The best part, you only need ONE phone line to dial
          in to our system with, we turn that one line in to 3 on our end, EASY!
        </HelpPopover>
      );
      break;
    case 'call_recording':
      content = (
        <HelpPopover
          title="Digital Call Recording"
          style={{ marginLeft: '14px' }}
        >
          Want to record sales calls for quality assurance, training or future
          review? Mojo can do it automatically—making digital sound recordings
          of your calls. This feature is ${price} per month, per dialing
          license. Just select how many you need.
        </HelpPopover>
      );
      break;
    case 'call_me':
      content = (
        <HelpPopover title="Call Me Feature" style={{ marginLeft: '14px' }}>
          To use Mojo, you will dial in to our New Hampshire dialing servers
          where the magic happens. This may be a long distance call. If you do
          not have an unlimited long distance plan, you may incur charges from
          your telco provider. You can add the Call Me Feature for ${price} per
          dialing license which will allow our servers to call you instead,
          avoiding any long distance charges.
        </HelpPopover>
      );
      break;

    case 'nhs':
      content = (
        <HelpPopover title="Neighborhood Search" style={{ marginLeft: '14px' }}>
          <p>
            Data includes Landline Phones, Mobile Phones and Email Addresses
            where available.
          </p>
          <p>
            You can print Neighborhood Search data and export Names/Addresses.
          </p>
        </HelpPopover>
      );
      break;
    case 'expired':
      content = (
        <HelpPopover title="Expired Data" style={{ marginLeft: '14px' }}>
          <p>
            High quality off-market real estate data including expired,
            cancelled and withdrawn properties.
          </p>
          <p>You can select your MLS after sign up.</p>
          <p>
            Data includes Landline Phones, Mobile Phones and Email Addresses
            where available.
          </p>
        </HelpPopover>
      );
      break;

    case 'fsbo':
      content = (
        <HelpPopover title="FSBO" style={{ marginLeft: '14px' }}>
          <p>Real time FSBO leads from all major US sources.</p>
          <p>You can select your county after sign up.</p>
          <p>
            Data includes Landline Phones, Mobile Phones and Email Addresses
            where available.
          </p>
        </HelpPopover>
      );
      break;
    case 'frbo':
      content = (
        <HelpPopover title="FRBO" style={{ marginLeft: '14px' }}>
          <p>
            Real-time For Rent By Owner leads from all major rental sources. You
            will select your county after sign-up.
          </p>
          <p>
            Data includes owner name, landline phones, mobile phone and email
            where available.
          </p>
        </HelpPopover>
      );
      break;
    case 'skip_tracer':
      content = (
        <HelpPopover title="Skip Tracer" style={{ marginLeft: '14px' }}>
          <p>
            Our Skip Tracer service allows you to submit addresses and find
            contact names, landlines, mobile phones and emails for them.
          </p>
          <p>
            Data includes Landline Phones, Mobile Phones and Email Addresses
            where available.
          </p>
        </HelpPopover>
      );
      break;
    case 'pre_foreclosure':
      content = (
        <HelpPopover title="Pre-Foreclosure" style={{ marginLeft: '14px' }}>
          <p>
            Pre-foreclosure leads provide a lucrative opportunity for you to
            help distressed homeowners. These homeowners are motivated to list
            their homes with Real Estate Agents or to sell directly to real
            estate investors.
          </p>
          <p>
            Our pre-foreclosure leads come direct from the county and are
            delivered weekly within the Mojo system. Information includes
            property details, owner details, financial details and contact
            information including landlines, mobile phones and email (where
            available).
          </p>
        </HelpPopover>
      );
      break;
    default:
      content = null;
  }
  return content;
};

const getTypeTitle = (accType) => {
  if (accType === 'multi_user') {
    return (
      <div className={styles.TitleWithIcon}>
        <img src={multiUserIcon} className={styles.UserIcon} alt="" />
        Multi User
      </div>
    );
  }
  return (
    <div className={styles.TitleWithIcon}>
      <img src={singleUserIcon} className={styles.UserIcon} alt="" />
      Single User
    </div>
  );
};

const getStepTitle = (dataAndDialer) => {
  let titlePart2 = '';
  switch (dataAndDialer) {
    case 'data_and_dialer':
      titlePart2 = 'Dialer and Data';
      break;
    case 'dialer':
      titlePart2 = 'Dialer Only';
      break;
    case 'data':
      titlePart2 = 'Data Only';
      break;
    default:
      titlePart2 = 'Dialer and Data';
  }
  return `${titlePart2} Account`;
};

const getCoveragePopupContent = (service) => {
  let serviceName = null;
  switch (service) {
    case 'skip_tracer':
      serviceName = 'Mojo Skip Tracer';
      break;
    case 'nhs':
      serviceName = 'Mojo Neighborhood Search';
      break;
    case 'pre_foreclosure':
      serviceName = 'Mojo Pre-Foreclosure';
      break;
    default:
      break;
  }

  return (
    <div className={styles.UncoveredStatesPopup}>
      <p>
        Unfortunately, {serviceName} does not have coverage in Canada or in the
        states listed below.
      </p>
      <ul>
        <li>South Carolina</li>
        <li>Montana</li>
        <li>Idaho</li>
        <li>Kansas</li>
      </ul>
      <p>
        If you plan on using this service outside of the states listed above,
        please proceed.
      </p>
    </div>
  );
};

const StepThree = (props) => {
  const {
    onSetStep,
    onSetStepCompleted,
    onUpdateSignUpData,
    sendGAEvent,
    renameGAEvent,
    stepNumber,
    stepCompleted,
    signUpData,
    accountType,
    dataAndDialerChoice,
    prices,
    misc,
    history,
    totalPrice,
  } = props;

  const hlMultipleValue = accountType === 'multi_user' ? 1 : 0;
  const hlSelectedValue =
    signUpData && signUpData.hosted_lead_management_count !== null
      ? signUpData.hosted_lead_management_count
      : 0;
  const slSelectedValue =
    signUpData && signUpData.single_line_power_dialer_count !== null
      ? signUpData.single_line_power_dialer_count
      : 0;
  const tlSelectedValue =
    signUpData && signUpData.triple_line_power_dialer_count !== null
      ? signUpData.triple_line_power_dialer_count
      : 0;
  const crSelectedValue =
    signUpData && signUpData.call_recording_feature_count !== null
      ? signUpData.call_recording_feature_count
      : 0;
  const cmSelectedValue =
    signUpData && signUpData.call_me_feature_count !== null
      ? signUpData.call_me_feature_count
      : 0;
  const nhsSelectedValue =
    signUpData && signUpData.neighborhood_search_feature !== null
      ? signUpData.neighborhood_search_feature
      : 0;
  const fsboSelectedValue =
    signUpData && signUpData.fsbo_data_feature !== null
      ? signUpData.fsbo_data_feature
      : 0;
  const frboSelectedValue =
    signUpData && signUpData.frbo_data_feature !== null
      ? signUpData.frbo_data_feature
      : 0;
  const stSelectedValue =
    signUpData && signUpData.reverse_lookup_feature !== null
      ? signUpData.reverse_lookup_feature
      : 0;
  const edSelectedValue =
    signUpData && signUpData.expired_data_feature !== null
      ? signUpData.expired_data_feature
      : 0;
  const pfclSelectedValue =
    signUpData && signUpData.pre_foreclosure_data_feature !== null
      ? signUpData.pre_foreclosure_data_feature
      : 0;
  const showRlAlertPopup = misc !== null ? misc.show_rl_alert_popup : undefined;
  const showNhsAlertPopup =
    misc !== null ? misc.show_nhs_alert_popup : undefined;
  const showPfclAlertPopup =
    misc !== null ? misc.show_pfcl_alert_popup : undefined;
  const shouldAllowExpiredData = signUpData.industry === 'Real Estate';

  const [validationError, setValidationError] = useState(null);
  const [coveragePopupService, setCoveragePopupService] = useState(null);
  const [stCoveragePopupWasShown, setStCoveragePopupWasShown] = useState(false);
  const [nhsCoveragePopupWasShown, setNhsCoveragePopupWasShown] =
    useState(false);
  const [pfclCoveragePopupWasShown, setPfclCoveragePopupWasShown] =
    useState(false);
  const [showSkipTracerNotificationPopup, setShowSkipTracerNotificationPopup] =
    useState(null);

  const hlOpts = getOpts(1, 20);
  const slOpts = getOpts(0, 20);
  const tlOpts = getOpts(0, 20);
  const crOpts = getOpts(0, 20);
  const cmOpts = getOpts(0, 20);

  useEffect(() => {
    if (stepNumber !== 3) {
      onSetStep(3);
    }
  }, [onSetStep, stepNumber]);

  // Scroll screen to top once mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleHlChanged = (count) => {
    onUpdateSignUpData({
      hosted_lead_management_count: count + hlMultipleValue,
    });
  };

  const handleSlChanged = (count) => {
    if (accountType === 'single_user') {
      onUpdateSignUpData({
        single_line_power_dialer_count: 1,
        triple_line_power_dialer_count: 0,
      });
    } else if (accountType === 'multi_user') {
      onUpdateSignUpData({
        single_line_power_dialer_count: count,
      });
    }
  };

  const handleTlChanged = (count) => {
    if (accountType === 'single_user') {
      onUpdateSignUpData({
        single_line_power_dialer_count: 0,
        triple_line_power_dialer_count: 1,
      });
    } else if (accountType === 'multi_user') {
      onUpdateSignUpData({
        triple_line_power_dialer_count: count,
      });
    }
  };

  const handleCrChanged = (count) => {
    onUpdateSignUpData({
      call_recording_feature_count: count,
    });
  };

  const handleCmChanged = (count) => {
    onUpdateSignUpData({
      call_me_feature_count: count,
    });
  };

  const handleNhsChanged = (event) => {
    const count = event.target.checked ? 1 : 0;
    onUpdateSignUpData({
      neighborhood_search_feature: count,
    });
  };

  const handleFsboChanged = (event) => {
    const count = event.target.checked ? 1 : 0;
    onUpdateSignUpData({
      fsbo_data_feature: count,
    });
  };

  const handleFrboChanged = (event) => {
    const count = event.target.checked ? 1 : 0;
    onUpdateSignUpData({
      frbo_data_feature: count,
    });
  };

  const handleStChanged = (event) => {
    const count = event.target.checked ? 1 : 0;
    if (count === 1 && showSkipTracerNotificationPopup === null) {
      setShowSkipTracerNotificationPopup(true);
    }
    onUpdateSignUpData({
      reverse_lookup_feature: count,
    });
  };

  const handleEdChanged = (event) => {
    const count = event.target.checked ? 1 : 0;
    onUpdateSignUpData({
      expired_data_feature: count,
    });
  };

  const handlePfclChanged = (event) => {
    const count = event.target.checked ? 1 : 0;
    onUpdateSignUpData({
      pre_foreclosure_data_feature: count,
    });
  };

  const validateSubscriptions = useCallback(() => {
    if (
      showRlAlertPopup !== undefined &&
      showNhsAlertPopup !== undefined &&
      showPfclAlertPopup !== undefined
    ) {
      if (slSelectedValue + tlSelectedValue > hlSelectedValue) {
        setValidationError(
          'You have selected more dialer licenses than you have selected Mojo CRM licenses. This is usually a mistake so we have reset the dialer values to zero. Please make sure the number of dialer licenses you select matches the number of Mojo CRM licenses you select.'
        );
        onUpdateSignUpData({
          single_line_power_dialer_count: 0,
          triple_line_power_dialer_count: 0,
        });
      } else if (crSelectedValue > hlSelectedValue) {
        setValidationError(
          'The Call Recording Feature licenses can`t exceed the count of Mojo CRM licenses selected. Please provide the correct value.'
        );
        onUpdateSignUpData({
          call_recording_feature_count: 0,
        });
      } else if (cmSelectedValue > hlSelectedValue) {
        setValidationError(
          'The Call Me Feature licenses can`t exceed the count of Mojo CRM licenses selected. Please provide the correct value.'
        );
        onUpdateSignUpData({
          call_me_feature_count: 0,
        });
      } else if (
        nhsSelectedValue === 1 &&
        showNhsAlertPopup &&
        !nhsCoveragePopupWasShown
      ) {
        setCoveragePopupService('nhs');
      } else if (
        stSelectedValue === 1 &&
        showRlAlertPopup &&
        !stCoveragePopupWasShown
      ) {
        setCoveragePopupService('skip_tracer');
      } else if (
        pfclSelectedValue === 1 &&
        showPfclAlertPopup &&
        !pfclCoveragePopupWasShown
      ) {
        setCoveragePopupService('pre_foreclosure');
      }
    }
  }, [
    onUpdateSignUpData,
    hlSelectedValue,
    slSelectedValue,
    tlSelectedValue,
    cmSelectedValue,
    crSelectedValue,
    stSelectedValue,
    nhsSelectedValue,
    pfclSelectedValue,
    showRlAlertPopup,
    showNhsAlertPopup,
    showPfclAlertPopup,
    stCoveragePopupWasShown,
    nhsCoveragePopupWasShown,
    pfclCoveragePopupWasShown,
  ]);

  useEffect(() => {
    validateSubscriptions();
  }, [
    validateSubscriptions,
    hlSelectedValue,
    slSelectedValue,
    tlSelectedValue,
    cmSelectedValue,
    crSelectedValue,
    stSelectedValue,
    nhsSelectedValue,
    pfclSelectedValue,
  ]);

  const handleValidationErrorClosed = () => {
    setValidationError(null);
  };

  const handleCoveragePopupClosed = (service, useService) => {
    setCoveragePopupService(null);
    switch (service) {
      case 'skip_tracer':
        setStCoveragePopupWasShown(true);
        break;
      case 'nhs':
        setNhsCoveragePopupWasShown(true);
        break;
      case 'pre_foreclosure':
        setPfclCoveragePopupWasShown(true);
        break;
      default:
        break;
    }
    if (!useService) {
      switch (service) {
        case 'skip_tracer':
          onUpdateSignUpData({ reverse_lookup_feature: 0 });
          break;
        case 'nhs':
          onUpdateSignUpData({ neighborhood_search_feature: 0 });
          break;
        case 'pre_foreclosure':
          onUpdateSignUpData({ pre_foreclosure_data_feature: 0 });
          break;
        default:
          break;
      }
    }
  };

  const validateSubscriptionsOnSubmit = () => {
    let isValid = true;
    if (
      accountType === 'multi_user' &&
      (dataAndDialerChoice === 'data_and_dialer' ||
        dataAndDialerChoice === 'dialer') &&
      slSelectedValue === 0 &&
      tlSelectedValue === 0
    ) {
      isValid = false;
      setValidationError(
        'Please select at least 1 single line or triple line power dialer.'
      );
    } else if (
      (accountType === 'single_user' || accountType === 'multi_user') &&
      dataAndDialerChoice === 'data' &&
      nhsSelectedValue === 0 &&
      fsboSelectedValue === 0 &&
      frboSelectedValue === 0 &&
      stSelectedValue === 0 &&
      edSelectedValue === 0 &&
      pfclSelectedValue === 0
    ) {
      isValid = false;
      setValidationError('Please select at least one data service.');
    }
    return isValid;
  };

  const handleSubmitStep = () => {
    if (validateSubscriptionsOnSubmit()) {
      onSetStepCompleted(3);
      sendGAEvent(
        `${renameGAEvent(accountType)} ${renameGAEvent(
          dataAndDialerChoice
        )} -> Customer and Account Information`
      );
      history.push('/sign-up/step-4');
    }
  };

  let content = null;
  if (prices) {
    let dialerBlock = null;
    if (
      accountType === 'multi_user' &&
      (dataAndDialerChoice === 'data_and_dialer' ||
        dataAndDialerChoice === 'dialer')
    ) {
      dialerBlock = (
        <>
          <p className={styles.Subtitle}>
            Choose dialer licenses type and quantity:
          </p>
          <div className={styles.Notice}>
            <p>
              * Dialer licenses can be shared by users at different times.
              Simultaneous dialing requires multiple licenses.
            </p>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '300px' }}>
              Single line power dialer (${prices.SINGLE_LINE_POWER_DIALER} per
              license):
            </div>
            <div style={{ width: '200px' }}>
              <Select
                changed={(option) => handleSlChanged(option.value)}
                value={
                  slSelectedValue !== null ? slOpts[slSelectedValue] : undefined
                }
                defaultValue={slOpts[1]}
                options={slOpts}
                price={prices.SINGLE_LINE_POWER_DIALER}
                autoComplete="off"
              />
            </div>
            {getHelpPopover('single_line')}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '300px' }}>
              Triple line power dialer (${prices.TRIPLE_LINE_POWER_DIALER} per
              license):
            </div>
            <div style={{ width: '200px' }}>
              <Select
                changed={(option) => handleTlChanged(option.value)}
                value={
                  tlSelectedValue !== null ? slOpts[tlSelectedValue] : undefined
                }
                defaultValue={tlOpts[0]}
                options={tlOpts}
                price={prices.TRIPLE_LINE_POWER_DIALER}
                autoComplete="off"
              />
            </div>
            {getHelpPopover('triple_line')}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '300px' }}>
              Call recording feature (${prices.CALL_RECORDING_FEATURE} per
              user):
            </div>
            <div style={{ width: '200px' }}>
              <Select
                changed={(option) => handleCrChanged(option.value)}
                value={
                  crSelectedValue !== null ? crOpts[crSelectedValue] : undefined
                }
                defaultValue={crOpts[0]}
                options={crOpts}
                price={prices.CALL_RECORDING_FEATURE}
                autoComplete="off"
              />
            </div>
            {getHelpPopover('call_recording', prices.CALL_RECORDING_FEATURE)}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '300px' }}>
              Call me feature (${prices.CALL_ME_FEATURE} per user):
            </div>
            <div style={{ width: '200px' }}>
              <Select
                changed={(option) => handleCmChanged(option.value)}
                value={
                  cmSelectedValue !== null ? cmOpts[cmSelectedValue] : undefined
                }
                defaultValue={cmOpts[0]}
                options={cmOpts}
                price={prices.CALL_ME_FEATURE}
                autoComplete="off"
              />
            </div>
            {getHelpPopover('call_me', prices.CALL_ME_FEATURE)}
          </div>
        </>
      );
    } else if (
      accountType === 'single_user' &&
      (dataAndDialerChoice === 'data_and_dialer' ||
        dataAndDialerChoice === 'dialer')
    ) {
      const slCardIsActive = slSelectedValue || !tlSelectedValue;
      const tlCardIsActive = tlSelectedValue;
      dialerBlock = (
        <>
          <div style={{ marginTop: '30px' }}>
            <ChoiceCard
              cardType="SingleLineDialer"
              isActive={slCardIsActive}
              clicked={() => handleSlChanged(1)}
            />
            <ChoiceCard
              cardType="TripleLineDialer"
              isActive={tlCardIsActive}
              clicked={() => handleTlChanged(1)}
            />
          </div>
          <div style={{ marginTop: '30px' }}>
            <div>
              <Checkbox
                name="call_rec"
                changed={(event) =>
                  event.target.checked ? handleCrChanged(1) : handleCrChanged(0)
                }
                isChecked={crSelectedValue === 1}
                autoComplete="off"
              >
                <span style={{ marginRight: '10px' }}>
                  Call Recording Feature
                </span>
                <Price price={prices.CALL_RECORDING_FEATURE} />
                {getHelpPopover(
                  'call_recording',
                  prices.CALL_RECORDING_FEATURE
                )}
              </Checkbox>
            </div>
            <div style={{ marginTop: '10px' }}>
              <Checkbox
                name="call_me"
                changed={(event) =>
                  event.target.checked ? handleCmChanged(1) : handleCmChanged(0)
                }
                isChecked={cmSelectedValue === 1}
                autoComplete="off"
              >
                <span style={{ marginRight: '10px' }}>Call Me Feature</span>{' '}
                <Price price={prices.CALL_ME_FEATURE} />
                {getHelpPopover('call_me', prices.CALL_ME_FEATURE)}
              </Checkbox>
            </div>
          </div>
        </>
      );
    }

    let dataBlock = null;
    if (
      dataAndDialerChoice === 'data_and_dialer' ||
      dataAndDialerChoice === 'data'
    ) {
      dataBlock = (
        <>
          <div className={styles.Title} style={{ marginTop: '30px' }}>
            Let&#39;s add some data:
          </div>
          <div className={styles.Notice}>
            Only available in the United States
          </div>
          <div style={{ marginTop: '30px' }}>
            <div>
              <Checkbox
                name="nhs"
                changed={handleNhsChanged}
                isChecked={nhsSelectedValue === 1}
                autoComplete="off"
              >
                <span style={{ marginRight: '10px' }}>
                  Mojo Neighborhood search{' '}
                  <span className={styles.ReferenceAnchor}>2</span>
                </span>
                <Price price={prices.LEADSTORE_ONE_MONTH_PRICE} />
                {getHelpPopover('nhs')}
              </Checkbox>
            </div>

            {shouldAllowExpiredData && (
              <div style={{ marginTop: '10px' }}>
                <Checkbox
                  name="expired"
                  changed={handleEdChanged}
                  isChecked={edSelectedValue === 1}
                  autoComplete="off"
                >
                  <span style={{ marginRight: '10px' }}>
                    Mojo Expired/Off-market data service (per MLS){' '}
                    <span className={styles.ReferenceAnchor}>1</span>
                  </span>
                  <Price price={prices.EXPIREDDATA_ONE_MONTH_PRICE} />
                  {getHelpPopover('expired')}
                </Checkbox>
              </div>
            )}

            <div style={{ marginTop: '10px' }}>
              <Checkbox
                name="fsbo"
                changed={handleFsboChanged}
                isChecked={fsboSelectedValue === 1}
                autoComplete="off"
              >
                <span style={{ marginRight: '10px' }}>
                  Mojo For Sale By Owner leads (per county)
                </span>
                <Price price={prices.FSBO_ONE_MONTH_PRICE} />
                {getHelpPopover('fsbo')}
              </Checkbox>
            </div>
            <div style={{ marginTop: '10px' }}>
              <Checkbox
                name="frbo"
                changed={handleFrboChanged}
                isChecked={frboSelectedValue === 1}
                autoComplete="off"
              >
                <span style={{ marginRight: '10px' }}>
                  Mojo For Rent By Owner leads (per county)
                </span>
                <Price price={prices.FRBO_ONE_MONTH_PRICE} />
                {getHelpPopover('frbo')}
              </Checkbox>
            </div>
            <div style={{ marginTop: '10px' }}>
              <Checkbox
                name="st"
                changed={handleStChanged}
                isChecked={stSelectedValue === 1}
                autoComplete="off"
              >
                <span style={{ marginRight: '10px' }}>
                  Mojo Skip Tracer (unlimited use){' '}
                  <span className={styles.ReferenceAnchor}>2</span>
                </span>
                <Price price={prices.REVERSE_LOOKUP_ONE_MONTH_PRICE} />
                {getHelpPopover('skip_tracer')}
              </Checkbox>
            </div>
            <div style={{ marginTop: '10px' }}>
              <Checkbox
                name="pre-foreclosure"
                changed={handlePfclChanged}
                isChecked={pfclSelectedValue === 1}
                autoComplete="off"
              >
                <span style={{ marginRight: '10px' }}>
                  Mojo Pre-Foreclosure (per county)
                  <span className={styles.ReferenceAnchor}>2</span>
                </span>
                <Price price={prices.PRE_FORECLOSURE_ONE_MONTH_PRICE} />
                {getHelpPopover('pre_foreclosure')}
              </Checkbox>
            </div>
            <div className={styles.Notice}>
              <p>
                * All Mojo data includes optional, complimentary National Do Not
                Call scrubbing for the U.S.
              </p>
              <p>* Some data exporting limits may apply.</p>
              <p>
                <span className={styles.ReferenceAnchor}>1</span> - Sold by 50
                mile radius and not available in all MLS.
              </p>
            </div>
          </div>
        </>
      );
    }

    content = (
      <>
        {validationError && (
          <Prompt
            isOpen
            style={{ width: '600px' }}
            title="Error"
            onClose={handleValidationErrorClosed}
            buttons={[
              {
                title: 'Ok',
                clicked: handleValidationErrorClosed,
                type: 'cancel',
              },
            ]}
          >
            <div style={{ lineHeight: '1.5' }}>{validationError}</div>
          </Prompt>
        )}
        {coveragePopupService && (
          <Prompt
            isOpen
            style={{ width: '600px' }}
            title="Warning"
            onClose={() =>
              handleCoveragePopupClosed(coveragePopupService, false)
            }
            buttons={[
              {
                title: 'Cancel',
                clicked: () =>
                  handleCoveragePopupClosed(coveragePopupService, false),
                type: 'cancel',
              },
              {
                title: 'Proceed',
                clicked: () =>
                  handleCoveragePopupClosed(coveragePopupService, true),
                type: 'success',
              },
            ]}
          >
            <div style={{ lineHeight: '1.5' }}>
              {getCoveragePopupContent(coveragePopupService)}
            </div>
          </Prompt>
        )}
        {showSkipTracerNotificationPopup && (
          <Prompt
            isOpen
            style={{ width: '600px' }}
            title="Info"
            onClose={() => setShowSkipTracerNotificationPopup(false)}
            buttons={[
              {
                title: 'Ok',
                clicked: () => setShowSkipTracerNotificationPopup(false),
                type: 'success',
              },
            ]}
          >
            <div style={{ lineHeight: '1.5' }}>
              Mojo Skip Tracer data is intended to be used within Mojo. You can
              perform unlimited skip traces within the program but you are
              limited to exporting 3000 records per calendar month.
            </div>
          </Prompt>
        )}
        <div className={styles.Title}>
          {getTypeTitle(accountType)}
          <div>{getStepTitle(dataAndDialerChoice)}</div>
        </div>
        <div className={styles.Notice}>
          * Additional users, dialing licenses and data subscriptions may be
          added at any time after signing up.
        </div>
        {accountType === 'multi_user' ? (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '16px',
              }}
            >
              <div style={{ width: '300px' }}>Owner Mojo CRM:</div>
              <div style={{ width: '163px', textAlign: 'end' }}>
                <Price price={prices.HOSTED_LEAD_MANAGEMENT} />
              </div>
              <div style={{ width: '37px' }}> </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '300px' }}>
                Additional User Access (${prices.HOSTED_LEAD_MANAGEMENT} each):
              </div>
              <div style={{ width: '200px' }}>
                <Select
                  changed={(option) => handleHlChanged(option.value)}
                  value={
                    hlSelectedValue !== null
                      ? hlOpts[hlSelectedValue - 1 - hlMultipleValue]
                      : undefined
                  }
                  defaultValue={hlOpts[0]}
                  options={hlOpts}
                  price={prices.HOSTED_LEAD_MANAGEMENT}
                  autoComplete="off"
                />
              </div>
              {getHelpPopover('hosted_lead')}
            </div>

            {dialerBlock}
          </>
        ) : (
          <>
            {dataAndDialerChoice === 'data' ? (
              <div className={styles.PreselectedHostedTitle}>
                <span>Hosted Lead Access</span>
                <Price price={prices.HOSTED_LEAD_MANAGEMENT} />
                <span>{getHelpPopover('hosted_lead')}</span>
              </div>
            ) : null}
            {dialerBlock}
          </>
        )}

        {dataBlock}

        <div className={styles.SubtotalWrap}>
          <span className={styles.SubtotalLabel}>Subtotal:</span>
          <div style={{ display: 'flex', width: '120px' }}>
            <Price price={totalPrice} />
          </div>
        </div>

        <div className={styles.ButtonsWrap}>
          <Button
            onClick={() => {
              sendGAEvent(
                `Dialer and Data choices <- ${renameGAEvent(
                  accountType
                )} ${renameGAEvent(dataAndDialerChoice)}`
              );
              history.push('/sign-up/step-2');
            }}
            isBtnPrev
            style={{ width: '146px' }}
          >
            Previous
          </Button>
          <Button
            onClick={handleSubmitStep}
            isBtnNext
            style={{ width: '146px' }}
          >
            Next
          </Button>
        </div>
      </>
    );
  }

  let redirect = null;
  if (stepCompleted === null || stepCompleted < 2) {
    redirect = <Redirect to="/sign-up/step-1" />;
  }

  return (
    <div className={styles.Step}>
      {redirect}
      {content}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stepNumber: state.signUp.stepNumber,
    stepCompleted: state.signUp.stepCompleted,
    signUpData: state.signUp.data,
    accountType: state.signUp.accountType,
    dataAndDialerChoice: state.signUp.dataAndDialerChoice,
    prices: state.signUp.prices,
    misc: state.signUp.misc,
    totalPrice: state.signUp.totalPrice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetStep: (stepNum) => dispatch(actions.setStepNumber(stepNum)),
    onSetStepCompleted: (stepNum) =>
      dispatch(actions.setStepCompleted(stepNum)),
    onUpdateSignUpData: (signUpdata) =>
      dispatch(actions.updateSignUpData(signUpdata)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepThree);
