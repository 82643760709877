import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './PasswordReset.module.scss';
import Form from '../../components/UI/Form/Form';
import Button from '../../components/UI/Button/Button';
import CallTollFree from '../../components/Shared/CallTollFree/CallTollFree';
import AgentsPrompt from '../SignIn/AgentsPrompt/AgentsPrompt';
import * as api from '../../api/PasswordReset';

export default function PasswordReset() {
  const inputsScheme = {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        placeholder: 'Enter your email',
      },
      value: '',
      validation: {
        required: true,
        isEmail: true,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Email:',
    },
  };
  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState(null);
  const [errors, setErrors] = useState({});
  const [submittedformData, setSubmittedformData] = useState(null);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [isPasswordReseted, setIsPasswordReseted] = useState(false);
  const history = useHistory();

  const fetchAgents = useCallback(async (email) => {
    setIsLoading(true);
    try {
      const response = await api.checkAgentEmail(email);
      setAgents(response.data.login_data_list);
    } catch (e) {
      setErrors(e.message);
    }
    setIsLoading(false);
  }, []);

  const doPasswordReset = useCallback(async (formData) => {
    setIsLoading(true);
    try {
      await api.passwordReset(formData);
      setIsPasswordReseted(true);
    } catch (e) {
      if (e.response) {
        setErrors(e.response.data.errors);
      } else {
        setErrors(e.message);
      }
    }
    setIsLoading(false);
  }, []);

  const clearState = useCallback(() => {
    setAgents(null);
    setSelectedAgentId(null);
    setSubmittedformData(null);
  }, []);

  const handleOnSubmit = useCallback((event, formData) => {
    event.preventDefault();
    setSubmittedformData(formData);
  }, []);

  const handleOnAgentsPromptClose = useCallback(() => {
    clearState();
  }, [clearState]);

  const handleOnClickLoginOption = (agentId) => {
    setSelectedAgentId(agentId);
    setSubmittedformData({ ...submittedformData, pin: agentId });
  };

  useEffect(() => {
    if (submittedformData) {
      if (!agents) {
        fetchAgents(submittedformData.email);
      } else if (selectedAgentId || (agents && agents.length <= 1)) {
        doPasswordReset(submittedformData);
        clearState();
      }
    }
  }, [
    agents,
    submittedformData,
    selectedAgentId,
    doPasswordReset,
    clearState,
    fetchAgents,
  ]);

  return (
    <div className={styles.PasswordReset}>
      {agents && agents.length > 1 && (
        <AgentsPrompt
          isOpen
          agents={agents}
          optionBtnLabel="Reset"
          onClose={handleOnAgentsPromptClose}
          onLoginOptionClick={handleOnClickLoginOption}
        />
      )}
      <div className={styles.Title}>Reset password</div>
      <div className={styles.PasswordResetInner}>
        {isPasswordReseted ? (
          <>
            <div className={styles.Success}>Success!</div>
            <div className={styles.CheckYourEmail}>
              Please check your Email.
            </div>
            <Button
              type="button"
              style={{ marginTop: '57px', width: '146px' }}
              onClick={() => history.push('/login/')}
            >
              Back to Login
            </Button>
          </>
        ) : (
          <>
            <div className={styles.SubTitle}>
              Please enter E-mail to resend password:
            </div>
            <Form
              inputsScheme={inputsScheme}
              onSubmit={handleOnSubmit}
              style={{ paddingTop: '18px' }}
              errorsScheme={errors}
            >
              <Button
                type="submit"
                isBtnNext
                style={{ marginTop: '45px', width: '146px' }}
                isLoading={isLoading}
              >
                Send
              </Button>
            </Form>

            <div className={styles.DidYouRemember}>
              <div>Did you remember your password?</div>
              <div>
                <a href="/login/">Try logging in</a>
              </div>
            </div>
            <CallTollFree />
          </>
        )}
      </div>
    </div>
  );
}
