import React from 'react';
import styles from './Button.module.css';

export default function Button(props) {
  const {
    onClick,
    type,
    isBtnNext,
    isBtnPrev,
    style,
    children,
    isLoading,
    disabled,
  } = props;

  const classes = [styles.Button];

  if (isBtnNext) {
    classes.push(styles.ButtonNext);
  } else if (isBtnPrev) {
    classes.push(styles.ButtonPrev);
  }

  if (isLoading) {
    classes.push(styles.ButtonIsLoading);
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      className={classes.join(' ')}
      style={style}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
