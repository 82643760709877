import React from 'react';

import OneColumnHeader from './Header/Header';
import DataRouter from '../../../router/DataRouter';
// import GlobalRouter from '../../../router/GlobalRouter';

// import DefaultRouter from '../../router';

export default function OneColumnLayout() {
  return (
    <>
      <OneColumnHeader />
      <DataRouter />
    </>
  );
}
