import ax from './axios';
import { formDataFromParams } from './utils';

export const checkAgentEmail = (email) => {
  return ax({
    url: '/rest/auth/check_agent_email/',
    method: 'post',
    data: formDataFromParams({ email }),
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });
};

export const passwordReset = (formData) => {
  return ax({
    url: '/rest/auth/password_reset/',
    method: 'post',
    data: formDataFromParams(formData),
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });
};

export const validateToken = (token) => {
  return ax({
    url: `/rest/auth/password_reset/confirm/${token}/`,
    method: 'get',
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });
};

export const passwordResetConfirm = (token, formData) => {
  return ax({
    url: `/rest/auth/password_reset/confirm/${token}/`,
    method: 'post',
    data: formDataFromParams(formData),
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });
};
