import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import Button from '../../../../components/UI/Button/Button';
import SelectInput from '../StepFour/SelectInput/SelectInput';
import ChoiceCard from '../../../../components/UI/SignUp/ChoiceCard';
import Prompt from '../../../../components/Shared/Prompt/Prompt';
import styles from './StepTwo.module.css';
import { checkValidity, updateObject } from '../../../../shared/utility';
import * as actions from '../../../../redux/actions/index';

const getSelectedOption = (options, value) => {
  let searchedOption = null;
  Object.values(options).forEach((option) => {
    if (option.value === value) {
      searchedOption = option;
    }
  });
  return searchedOption;
};

const StepTwo = (props) => {
  const {
    onSetStep,
    onSetStepCompleted,
    onInitContext,
    renameGAEvent,
    sendGAEvent,
    currentStep,
    stepCompleted,
    history,
    accountType,
    onSetAccountType,
    dataAndDialerChoice,
    onSetDataAndDialerChoice,
    prices,
    signUpData,
    onUpdateSignUpData,
  } = props;
  const location = useLocation();

  const [showNewAccPrefilledPopup, setShowNewAccPrefilledPopup] =
    useState(false);
  const inputScheme = {
    industry: {
      elementType: 'select',
      elementConfig: {
        options: [
          // { value: '', label: 'Select Industry' },
          { value: 'Real Estate', label: 'Real Estate' },
          { value: 'Real Estate Investor', label: 'Real Estate Investor' },
          { value: 'Insurance', label: 'Insurance' },
          { value: 'Financial Services', label: 'Financial Services' },
          { value: 'Mortgage', label: 'Mortgage' },
          {
            value: 'Advertising/Marketing',
            label: 'Advertising/Marketing',
          },
          { value: 'Other', label: 'Other' },
        ],
        placeholder: 'Select Industry',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Industry:',
      inputWrapRef: React.createRef(),
    },
  };
  const inputSchemeKeys = Object.keys(inputScheme);
  const [infoForm, setInfoForm] = useState(inputScheme);

  useEffect(() => {
    if (location.search.includes('new_account')) {
      setShowNewAccPrefilledPopup(true);
    }
  }, [location.search]);

  useEffect(() => {
    if (currentStep !== 2) {
      onSetStep(2);
      if (!prices) {
        onInitContext();
      }
    }
  }, [onSetStep, currentStep, prices, onInitContext]);

  // Scroll screen to top once mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(
    () => {
      if (signUpData) {
        prefillFormValues(signUpData);
      }
    },
    // eslint-disable-next-line
    [signUpData]
  );

  const prefillFormValues = (prefillData) => {
    setInfoForm((previousInfoForm) => {
      let updatedForm = { ...previousInfoForm };
      Object.keys(previousInfoForm).forEach((controlName) => {
        if (prefillData[controlName]) {
          const updatedFormElement = updateObject(
            previousInfoForm[controlName],
            {
              value: prefillData[controlName],
            }
          );
          updatedForm = updateObject(updatedForm, {
            [controlName]: updatedFormElement,
          });
        }
      });
      return {
        ...previousInfoForm,
        ...updatedForm,
      };
    });
  };

  const checkFormValidity = () => {
    let isValid = true;
    let updatedForm = { ...infoForm };
    Object.keys(infoForm).forEach((inputIdentifier) => {
      const validationInfo = checkValidity(
        infoForm[inputIdentifier].value,
        infoForm[inputIdentifier].validation
      );
      const updatedFormElement = updateObject(infoForm[inputIdentifier], {
        value: infoForm[inputIdentifier].value,
        valid: validationInfo.isValid,
        error: validationInfo.error,
        touched: true,
      });
      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedFormElement,
      });
      isValid = updatedForm[inputIdentifier].valid && isValid;
    });

    return { isValid, updatedForm };
  };

  const getHighestErrorField = (updatedInputScheme) => {
    let highestField = null;
    Object.keys(updatedInputScheme).forEach((controlName) => {
      if (
        !updatedInputScheme[controlName].valid &&
        updatedInputScheme[controlName].inputWrapRef
      ) {
        if (!highestField) {
          highestField = updatedInputScheme[controlName];
        } else if (
          updatedInputScheme[controlName].inputWrapRef.current &&
          updatedInputScheme[controlName].inputWrapRef.current.offsetTop <
            highestField.inputWrapRef.current.offsetTop
        ) {
          highestField = updatedInputScheme[controlName];
        }
      }
    });

    return highestField;
  };

  const syncDataToRedux = useCallback(() => {
    const formData = {};
    Object.keys(infoForm).forEach((controlName) => {
      formData[controlName] = infoForm[controlName].value;
    });
    onUpdateSignUpData(formData);
  }, [onUpdateSignUpData, infoForm]);

  const inputChangedHandler = (inputValue, inputIdentifier) => {
    const validationInfo = checkValidity(
      inputValue,
      infoForm[inputIdentifier].validation
    );
    const updatedFormElement = updateObject(infoForm[inputIdentifier], {
      value: inputValue,
      valid: validationInfo.isValid,
      error: validationInfo.error,
      touched: true,
    });

    const updatedForm = updateObject(infoForm, {
      [inputIdentifier]: updatedFormElement,
    });

    setInfoForm(updatedForm);
  };

  const onNextBtnClick = () => {
    const formValidationInfo = checkFormValidity();
    if (!formValidationInfo.isValid) {
      setInfoForm(formValidationInfo.updatedForm);
      const highestControl = getHighestErrorField(
        formValidationInfo.updatedForm
      );
      if (highestControl) {
        window.scrollTo({
          behavior: 'smooth',
          top: highestControl.inputWrapRef.current.offsetTop - 100,
        });
      }
    } else {
      syncDataToRedux();
      onSetStepCompleted(2);
      sendGAEvent(
        `Dialer and Data choices -> ${renameGAEvent(
          accountType
        )} ${renameGAEvent(dataAndDialerChoice)}`
      );
      history.push('/sign-up/step-3');
    }
  };

  const onAccounTypeChangedHandler = (accType) => {
    onSetAccountType(accType);
  };

  const onDataAndDialerChangedHandler = (choice) => {
    onSetDataAndDialerChoice(choice);
  };

  const onCloseNewAccPrefilledPopup = () => {
    setShowNewAccPrefilledPopup(false);
  };

  let redirect = null;
  if (stepCompleted === null) {
    redirect = <Redirect to="/sign-up/step-1" />;
  }

  const infoFormElementsArray = Object.values(inputSchemeKeys).map((key) => {
    return {
      id: key,
      config: infoForm[key],
    };
  });

  return (
    <div className={styles.Step}>
      {redirect}
      {showNewAccPrefilledPopup && (
        <Prompt
          isOpen
          style={{ width: '400px' }}
          title="Notification"
          onClose={onCloseNewAccPrefilledPopup}
          buttons={[
            {
              title: 'Ok',
              clicked: onCloseNewAccPrefilledPopup,
              type: 'success',
            },
          ]}
        >
          <div style={{ lineHeight: '1.5' }}>
            You selected to start a new account, please complete the sign up to
            continue.
          </div>
        </Prompt>
      )}

      <div className={styles.Title}>What type of account would you like?</div>
      <div>
        <ChoiceCard
          cardType="SingleUserAcc"
          style={{ width: '204px' }}
          isActive={accountType === 'single_user' ? true : null}
          clicked={() => onAccounTypeChangedHandler('single_user')}
        />
        <ChoiceCard
          cardType="MultiUserAcc"
          style={{ width: '204px' }}
          isActive={accountType === 'multi_user' ? true : null}
          clicked={() => onAccounTypeChangedHandler('multi_user')}
        />
      </div>
      <div
        className={styles.Title}
        style={{ marginTop: '30px', width: '463px' }}
      >
        Are you looking for data and dialer, dialer only or data only?
      </div>
      <div>
        <ChoiceCard
          cardType="DialerAndData"
          isActive={dataAndDialerChoice === 'data_and_dialer' ? true : null}
          clicked={() => onDataAndDialerChangedHandler('data_and_dialer')}
        />
        <ChoiceCard
          cardType="DialerOnly"
          isActive={dataAndDialerChoice === 'dialer' ? true : null}
          clicked={() => onDataAndDialerChangedHandler('dialer')}
        />
        <ChoiceCard
          cardType="DataOnly"
          isActive={dataAndDialerChoice === 'data' ? true : null}
          clicked={() => onDataAndDialerChangedHandler('data')}
        />
      </div>

      <div className={styles.Title} style={{ marginTop: '30px' }}>
        What industry are you in?
      </div>
      <div>
        {infoFormElementsArray.map((formElement) => {
          if (formElement.config.elementType === 'select') {
            return (
              <SelectInput
                key={formElement.id}
                name={formElement.id}
                label={formElement.config.label}
                value={getSelectedOption(
                  formElement.config.elementConfig.options,
                  formElement.config.value
                )}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(option) =>
                  inputChangedHandler(option.value, formElement.id)
                }
                error={formElement.config.error}
                inputLabelStyle={{ width: '220px' }}
                options={formElement.config.elementConfig.options}
                placeholder={formElement.config.elementConfig.placeholder}
                defaultValue={formElement.config.elementConfig.options[0]}
                autoComplete="off"
                refProp={formElement.config.inputWrapRef}
              />
            );
          }
          return null;
        })}
      </div>

      <div className={styles.Notice}>
        * You can add additional users, dialing licenses or data products any
        time after sign up.
      </div>
      <div className={styles.ButtonsWrap}>
        <Button
          onClick={() => {
            sendGAEvent('Email Input <- Dialer and Data choices');
            history.push('/sign-up/step-1');
          }}
          isBtnPrev
          style={{ width: '146px' }}
        >
          Previous
        </Button>
        <Button onClick={onNextBtnClick} isBtnNext style={{ width: '146px' }}>
          Next
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentStep: state.signUp.stepNumber,
    stepCompleted: state.signUp.stepCompleted,
    accountType: state.signUp.accountType,
    dataAndDialerChoice: state.signUp.dataAndDialerChoice,
    prices: state.signUp.prices,
    signUpData: state.signUp.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetStep: (stepNum) => dispatch(actions.setStepNumber(stepNum)),
    onSetStepCompleted: (stepNum) =>
      dispatch(actions.setStepCompleted(stepNum)),
    onInitContext: () => dispatch(actions.initContext()),
    onSetAccountType: (accType) => dispatch(actions.setAccountType(accType)),
    onSetDataAndDialerChoice: (dataAndDialerVal) =>
      dispatch(actions.setDataAndDialerChoice(dataAndDialerVal)),
    onUpdateSignUpData: (signUpdata) =>
      dispatch(actions.updateSignUpData(signUpdata)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
