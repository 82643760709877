import { useEffect } from 'react';

const useImportScript = (resourceUrl, onLoaded) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.async = true;
    script.onload = onLoaded;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl, onLoaded]);
};
export default useImportScript;
