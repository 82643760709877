import React from 'react';

import styles from './TwoColumnsLayout.module.css';
import TwoColumnsHeader from './Header/Header';
import FooterSocial from '../../../components/Footer/FooterSocial/FooterSocial';
import FooterCopyright from '../../../components/Footer/FooterCopyright/FooterCopyright';
import SignUpProgress from '../../SignUp/SignUpProgress/SignUpProgress';
import DataRouter from '../../../router/DataRouter';

export default function TwoColumnsLayout() {
  return (
    <>
      <TwoColumnsHeader />
      <div style={{ display: 'flex', flex: 1 }}>
        <div
          className={[
            styles.TwoColumnsLayoutItem,
            styles.TwoColumnsLayoutItemDark,
          ].join(' ')}
        >
          <div
            className={[
              styles.TwoColumnsLayoutItemInner,
              styles.TwoColumnsLayoutItemInnerDark,
            ].join(' ')}
          >
            {/* <HeaderLogo /> */}
            <SignUpProgress />
            <FooterSocial />
          </div>
        </div>
        <div
          className={[
            styles.TwoColumnsLayoutItem,
            styles.TwoColumnsLayoutItemLight,
          ].join(' ')}
        >
          <div
            className={[
              styles.TwoColumnsLayoutItemInner,
              styles.TwoColumnsLayoutItemInnerLight,
            ].join(' ')}
          >
            {/* <HeaderMenu /> */}
            <DataRouter />
            <FooterCopyright />
          </div>
        </div>
      </div>
    </>
  );
}
