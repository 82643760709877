import React from 'react';
import styles from './Radio.module.scss';

export default function Radio(props) {
  const { children, style, name, value, isChecked, changed } = props;
  return (
    <label htmlFor={`id_${name}_${value}`} className={styles.Label}>
      <input
        style={style}
        type="radio"
        id={`id_${name}_${value}`}
        name={name}
        value={value}
        className={styles.Input}
        onChange={changed}
        checked={isChecked}
      />
      <span className={styles.Radio} />
      <span className={styles.Text}>{children}</span>
    </label>
  );
}
