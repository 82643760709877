import React from 'react';
import styles from './Price.module.scss';

export default function Price({ price }) {
  return (
    <span className={styles.Wrapper}>
      <span className={styles.Sign}>$</span>
      <span className={styles.Price}>{price}</span>
      <span className={styles.Period}> / mo</span>
    </span>
  );
}
