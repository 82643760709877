import React from 'react';
import styles from './CallTollFree.module.scss';

const checkIsOnline = () => {
  const day = new Date();
  const hr = day.getUTCHours();
  let isOnline = null;
  const isSummer = true; // set to `false` for Winter Time
  let daylightDelta = 0;

  if (!isSummer) {
    daylightDelta = 1;
  }

  if (
    (hr >= 0 + daylightDelta && hr < 13 + daylightDelta) ||
    (hr >= 21 + daylightDelta && hr <= 23 + daylightDelta) ||
    day.getDay() === 0 ||
    day.getDay() === 6
  ) {
    isOnline = false;
  } else {
    isOnline = true;
  }

  return isOnline;
};

export default function CallTollFree(props) {
  const { style } = props;
  return (
    <div
      style={style}
      className={[styles.CallTollFreeTitle, styles.CallTollFreeBottom].join(
        ' '
      )}
    >
      {checkIsOnline() ? (
        <>
          Call Toll Free:{' '}
          <span className={styles.CallTollFreePhone}>877-859-6656</span>{' '}
        </>
      ) : (
        <>
          <span style={{ marginRight: '15px' }}>Need some help?</span>
          <a href="https://www.mojosells.com/contact.html">
            Contact Mojo Support
          </a>
        </>
      )}
    </div>
  );
}
