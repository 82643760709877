import React from 'react';
import styles from './Logo.module.css';
import logoImgWhiteText from '../../assets/images/logo-mojo.svg';
import logoImgDarkText from '../../assets/images/logo-mojo-dark.svg';

export default function Logo(props) {
  const { isDark, style } = props;
  return (
    <img
      style={style}
      className={styles.Logo}
      src={isDark ? logoImgDarkText : logoImgWhiteText}
      alt="Mojo Logo"
    />
  );
}
