import ax from './axios';
import { formDataFromParams } from './utils';

export const getLoginInfoByEmail = (email, password) => {
  const data = { email };
  if (password) {
    data.password = password;
  }
  return ax({
    url: `/rest/auth/get-login-info-by-email/`,
    method: 'post',
    data: formDataFromParams(data),
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });
};

export const getSignUpContext = () => {
  return ax({
    url: `/rest/auth/get-signup-context/`,
    method: 'get',
    config: { headers: { 'Content-Type': 'application/json' } },
  });
};

export const signUp = (data, cancelToken) => {
  return ax({
    url: `/rest/auth/sign-up/`,
    method: 'post',
    data: formDataFromParams(data),
    config: { headers: { 'Content-Type': 'application/json' } },
    cancelToken,
  });
};

export const signUpPrefill = (token) => {
  return ax({
    url: `/rest/auth/sign-up/${token}/`,
    method: 'get',
    config: { headers: { 'Content-Type': 'application/json' } },
  });
};
