import React from 'react';
import styles from './Input.module.css';

export default function Input(props) {
  const {
    elementType,
    elementConfig,
    name,
    value,
    changed,
    invalid,
    shouldValidate,
    touched,
    error,
    label,
    inputLabelStyle,
    inputFieldWrapperStyle,
    inputWrapRef,
  } = props;
  let inputElement = null;
  const inputClasses = [styles.InputElement];
  const inputElementSpanClasses = [styles.inputElementSpanClasses];

  if (invalid && shouldValidate && touched) {
    inputClasses.push(styles.Invalid);
    inputElementSpanClasses.push(styles.Invalid);
  }

  switch (elementType) {
    case 'input':
      inputElement = (
        <input
          name={name}
          className={inputClasses.join(' ')}
          {...elementConfig}
          value={value}
          onChange={changed}
        />
      );
      break;
    case 'textarea':
      inputElement = (
        <textarea
          name={name}
          className={inputClasses.join(' ')}
          {...elementConfig}
          value={value}
          onChange={changed}
        />
      );
      break;
    case 'checkbox':
      inputElement = (
        <input
          name={name}
          className={inputClasses.join(' ')}
          {...elementConfig}
          value={value}
          onChange={changed}
        />
      );
      break;
    case 'select':
      inputElement = (
        <select
          name={name}
          className={inputClasses.join(' ')}
          value={value}
          onChange={changed}
          onBlur={() => {}}
        >
          {elementConfig.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    default:
      inputElement = (
        <input
          name={name}
          className={inputClasses.join(' ')}
          {...elementConfig}
          value={value}
          onChange={changed}
        />
      );
  }

  return (
    <div
      className={styles.Input}
      style={elementConfig.type === 'hidden' ? { display: 'none' } : null}
      ref={inputWrapRef}
    >
      <div className={styles.Label} style={inputLabelStyle}>
        {label}
      </div>
      <div
        className={styles.InputElementWrapper}
        style={inputFieldWrapperStyle}
      >
        <span className={inputElementSpanClasses.join(' ')}>
          {inputElement}
        </span>
        <div className={styles.InputElementError}>{error}</div>
      </div>
    </div>
  );
}
