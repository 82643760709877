import React from 'react';

import styles from './FooterSocial.module.css';
import youtubeLogo from '../../../assets/images/footer/youtube.svg';
import twitterLogo from '../../../assets/images/footer/twitter.svg';

const footerSocial = ({ side }) => {
  return (
    <div className={[styles.Footer, side].join(' ')}>
      <div className={styles.SocialWrap}>
        <a
          href="https://www.youtube.com/channel/UC-FTU9cu6h7yy7iILvQuHuQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={youtubeLogo} alt="Youtube" />
        </a>
        <a
          href="https://twitter.com/Mojosells"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={twitterLogo} alt="Twitter" />
        </a>
      </div>
    </div>
  );
};

export default footerSocial;
