import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './PasswordResetConfirm.module.scss';
import Form from '../../../components/UI/Form/Form';
import Button from '../../../components/UI/Button/Button';
import CallTollFree from '../../../components/Shared/CallTollFree/CallTollFree';
import * as api from '../../../api/PasswordReset';

export default function PasswordResetConfirm(props) {
  const inputsScheme = {
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Enter your password',
      },
      value: '',
      validation: {
        required: true,
        minLength: 6,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Password:',
    },
    password_confirm: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Confirm your password',
      },
      value: '',
      validation: {
        required: true,
        minLength: 6,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Confirm Password:',
    },
  };
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [submittedformData, setSubmittedformData] = useState(null);
  const [isPasswordReseted, setIsPasswordReseted] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(null);
  const history = useHistory();

  const { match } = props;
  const { token } = match.params;

  const handleOnSubmit = useCallback((event, formData) => {
    event.preventDefault();
    setSubmittedformData(formData);
  }, []);

  const validateToken = useCallback(async (token) => {
    setIsLoading(true);
    try {
      await api.validateToken(token);
      setIsTokenValid(true);
    } catch (e) {
      setIsTokenValid(false);
      setErrors(e.message);
    }
    setIsLoading(false);
  }, []);

  const doPasswordReset = useCallback(
    async (formData) => {
      setIsLoading(true);
      try {
        await api.passwordResetConfirm(token, formData);
        setIsPasswordReseted(true);
      } catch (e) {
        if (e.response) {
          setErrors(e.response.data.errors);
        } else {
          setErrors(e.message);
        }
      }
      setIsLoading(false);
    },
    [token]
  );

  useEffect(() => {
    validateToken(token);
  }, [token, validateToken]);

  useEffect(() => {
    if (submittedformData) {
      doPasswordReset(submittedformData);
    }
  }, [submittedformData, doPasswordReset]);

  let content = <div className={styles.SubTitle}>Page is loading ...</div>;
  if (isTokenValid === true) {
    if (isPasswordReseted) {
      content = (
        <>
          <div className={styles.Success}>Success!</div>
          <div className={styles.CheckYourEmail}>
            Please back to Login using your new password.
          </div>
          <Button
            type="button"
            style={{ marginTop: '57px', width: '146px' }}
            onClick={() => history.push('/login/')}
          >
            Back to Login
          </Button>
        </>
      );
    } else {
      content = (
        <>
          <div className={styles.SubTitle}>
            Please enter your new password twice:
          </div>
          <Form
            inputsScheme={inputsScheme}
            onSubmit={handleOnSubmit}
            style={{ paddingTop: '18px' }}
            errorsScheme={errors}
            inputLabelStyle={{ width: '150px' }}
          >
            <Button
              type="submit"
              style={{ marginTop: '45px', width: '146px' }}
              isLoading={isLoading}
            >
              Reset
            </Button>
          </Form>

          <div className={styles.DidYouRemember}>
            <div>Did you remember your password?</div>
            <div>
              <a href="/login/">Try logging in</a>
            </div>
          </div>
          <CallTollFree />
        </>
      );
    }
  } else if (isTokenValid === false) {
    content = (
      <>
        <div className={styles.SubTitle}>
          Unfortunately the link did not work. Please, attempt to restore the
          password once more
        </div>
        <Button
          type="button"
          style={{ marginTop: '57px', width: '146px' }}
          onClick={() => history.push('/password-reset/')}
        >
          Restore
        </Button>
      </>
    );
  }

  return (
    <div className={styles.PasswordResetConfirm}>
      <div className={styles.Title}>Reset password</div>
      <div className={styles.PasswordResetConfirmInner}>{content}</div>
    </div>
  );
}
