import React, { useState } from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import styles from './HelpPopover.module.scss';

export default function HelpPopover(props) {
  const [showPopover, setShowPopover] = useState(false);
  const { title, children, style } = props;
  return (
    <Popover
      isOpen={showPopover}
      position="right"
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor="rgba(44,52,64,0.98)"
          arrowSize={10}
        >
          <div className={styles.HelpPopoverText}>
            <div className={styles.HelpPopoverTitle}>{title}</div>
            <div className={styles.HelpPopoverContent}>{children}</div>
          </div>
        </ArrowContainer>
      )}
    >
      <div
        style={style}
        className={styles.HelpPopover}
        onMouseOver={() => {
          setShowPopover(true);
        }}
        onFocus={() => {
          setShowPopover(true);
        }}
        onMouseOut={() => {
          setShowPopover(false);
        }}
        onBlur={() => {
          setShowPopover(false);
        }}
      >
        <span>?</span>
      </div>
    </Popover>
  );
}
