import ax from './axios';
import { formDataFromParams } from './utils';

export const signIn = (formData) => {
  return ax({
    url: '/rest/auth/login/',
    method: 'post',
    data: formDataFromParams(formData),
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });
};

export const checkAgentEmail = (email) => {
  return ax({
    url: '/rest/auth/check_agent_email/',
    method: 'post',
    data: formDataFromParams({ email }),
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });
};
