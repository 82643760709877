export const SET_CONTEXT = 'SET_CONTEXT';
export const FETCH_CONTEXT_FAILED = 'FETCH_CONTEXT_FAILED';
export const SET_STEP_NUMBER = 'SET_STEP_NUMBER';
export const SET_STEP_COMPLETED = 'SET_STEP_COMPLETED';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_ACCOUNT_TYPE = 'SET_ACCOUNT_TYPE';
export const SET_DATA_AND_DIALER_CHOICE = 'SET_DATA_AND_DIALER_CHOICE';
export const UPDATE_SIGNUP_DATA = 'UPDATE_SIGNUP_DATA';
export const UPDATE_TEMP_SIGNUP_DATA = 'UPDATE_TEMP_SIGNUP_DATA';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
