import React from 'react';
import ReactSelect, { components } from 'react-select';

import Price from '../../../../../components/SignUp/Price/Price';
import styles from './SelectInput.module.scss';

const ValueContainerComponent = (props, customProps) => {
  let selectedCount = null;
  const { getValue } = props;
  const { price } = customProps;
  const valuesList = getValue();

  if (valuesList.length > 0) {
    selectedCount = valuesList[0].value;
  }
  return (
    <>
      <components.ValueContainer {...props} />
      {price && selectedCount !== null && (
        <Price price={selectedCount * price} />
      )}
    </>
  );
};

export default function CustomSelect(props) {
  // defaultValue: {value: 'value', label: 'Label'}
  // options: [{value: 'value', label: 'Label'}, {}, ...]
  // price: number
  const {
    placeholder,
    value,
    defaultValue,
    options,
    price,
    changed,
    isSearchable = true,
    invalid,
    shouldValidate,
    touched,
    error,
    label,
    inputLabelStyle,
    refProp,
    autoComplete,
  } = props;
  const inputClasses = [styles.InputElement];
  const inputElementSpanClasses = [styles.inputElementSpanClasses];

  if (invalid && shouldValidate && touched) {
    inputClasses.push(styles.Invalid);
    inputElementSpanClasses.push(styles.Invalid);
  }

  return (
    <div className={styles.Input} ref={refProp}>
      <div className={styles.Label} style={inputLabelStyle}>
        {label}
      </div>
      <div className={styles.InputElementWrapper}>
        <span className={inputElementSpanClasses.join(' ')}>
          <ReactSelect
            placeholder={placeholder}
            onChange={changed}
            value={value}
            defaultValue={defaultValue}
            options={options}
            autoFocus={false}
            isSearchable={isSearchable}
            autoComplete={autoComplete}
            components={{
              ValueContainer: (props) =>
                ValueContainerComponent({ ...props }, { price }),
            }}
            styles={{
              indicatorSeparator: (styles) => ({
                ...styles,
                display: 'none',
              }),
              dropdownIndicator: (styles) => ({
                ...styles,
                color: error ? '#e33333' : '#0046A4',
              }),
              control: (styles) => ({
                ...styles,
                width: '100%',
                border: 'none',
                borderRadius: '0px',
                borderBottom: '1px solid',
                borderBottomColor: error ? '#e33333' : '#D0D7E2',
                cursor: 'pointer',
                boxShadow: 'none',
                ':hover': {
                  ...styles[':hover'],
                  borderBottomColor: error ? '#e33333' : '#8C96A3',
                },
              }),
              container: (styles) => ({
                ...styles,
                border: 'none',
                outline: 'none',
              }),
            }}
          />
        </span>
        <div className={styles.InputElementError}>{error}</div>
      </div>
    </div>
  );
}
