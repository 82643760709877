import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import signUpReducer from './reducers/signUp';

const composeEnhancers =
  (process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const rootReducer = combineReducers({
  signUp: signUpReducer,
});

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
