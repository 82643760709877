import React from 'react';
import styles from './HeaderMenuItem.module.css';

export default function HeaderMenuItem(props) {
  const { url, name } = props;
  return (
    <div>
      <a
        href={url}
        className={styles.HeaderMenuItem}
        target="_blank"
        rel="noopener noreferrer"
      >
        {name}
      </a>
    </div>
  );
}
