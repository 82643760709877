import React from 'react';

import styles from './AppLayout.module.scss';
import GlobalRouter from '../../router/GlobalRouter';

// import DefaultRouter from '../../router';

export default function AppLayout() {
  return (
    <div className={styles.AppLayout}>
      <GlobalRouter />
    </div>
  );
}
