import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OneColumnLayout from '../containers/AppLayout/OneColumnLayout/OneColumnLayout';
import TwoColumnsLayout from '../containers/AppLayout/TwoColumnsLayout/TwoColumnsLayout';

const GlobalRouter = () => {
  return (
    <Switch>
      <Route path="/single" component={OneColumnLayout} />
      <Route path="*" component={TwoColumnsLayout} />
    </Switch>
  );
};

export default GlobalRouter;
