import React from 'react';

import styles from './ChoiceCard.module.scss';
import singleUserIcon from '../../../assets/images/signup/single-user-icon.svg';
import multiUserIcon from '../../../assets/images/signup/multi-user-icon.svg';
import dialerAndDataIcon from '../../../assets/images/signup/dialer-and-data-icon.svg';
import dialerIcon from '../../../assets/images/signup/dialer-icon.svg';
import dataIcon from '../../../assets/images/signup/data-icon.svg';
import singleLineDialerIcon from '../../../assets/images/signup/single-line-icon.svg';
import tripleLineDialerIcon from '../../../assets/images/signup/triple-line-icon.svg';
import Price from '../../SignUp/Price/Price';
import HelpPopover from '../../Shared/HelpPopover/HelpPopover';

const ChoiceCard = (props) => {
  const { isActive, cardType, style, clicked } = props;
  let cardContent = null;
  let extraClass = null;
  switch (cardType) {
    case 'SingleUserAcc':
      cardContent = (
        <>
          <img src={singleUserIcon} className={styles.SingleUserIcon} alt="" />
          <div className={styles.Title}>
            Single user account{' '}
            <div className={styles.TitleNotice}>(only one login)</div>
          </div>
        </>
      );
      break;
    case 'MultiUserAcc':
      cardContent = (
        <>
          <img src={multiUserIcon} className={styles.MultiUserIcon} alt="" />
          <div className={styles.Title}>
            Multi-user account{' '}
            <div className={styles.TitleNotice}>(more than one login)</div>
          </div>
        </>
      );
      break;
    case 'DialerAndData':
      cardContent = (
        <>
          <img
            src={dialerAndDataIcon}
            className={styles.DialerAndDataIcon}
            alt=""
          />
          <div className={styles.Title}>Dialer and Data</div>
        </>
      );
      extraClass = styles.DialerAndData;
      break;
    case 'DialerOnly':
      cardContent = (
        <>
          <img src={dialerIcon} className={styles.DialerOnlyIcon} alt="" />
          <div className={styles.Title}>Dialer only</div>
        </>
      );
      extraClass = styles.DialerOnly;
      break;
    case 'DataOnly':
      cardContent = (
        <>
          <img src={dataIcon} className={styles.DataOnlyIcon} alt="" />
          <div className={styles.Title}>Data only</div>
        </>
      );
      extraClass = styles.DataOnly;
      break;
    case 'SingleLineDialer':
      cardContent = (
        <>
          <img
            src={singleLineDialerIcon}
            className={styles.SingleLineDialerIcon}
            alt=""
          />
          <div className={styles.Title}>
            Single Line Power Dialer
            <HelpPopover
              title="Single Line Power Dialer"
              style={{ marginLeft: '8px' }}
            >
              Our Single line power dialer is perfect for those that want to
              automate their dialing routine and make 3x the amount of calls vs.
              dialing by hand. This option gives you the efficiency of up to 85
              Calls-per hour and the quality of first hello technology and
              crisp, clean conversations.
            </HelpPopover>
          </div>
          <div style={{ marginTop: '6px' }}>
            <Price price={99} />
          </div>
        </>
      );
      extraClass = styles.Dialer;
      break;
    case 'TripleLineDialer':
      cardContent = (
        <>
          <img
            src={tripleLineDialerIcon}
            className={styles.TripleLineDialerIcon}
            alt=""
          />
          <div className={styles.Title}>
            Triple Line Power Dialer
            <HelpPopover
              title="Triple Line Power Dialer"
              style={{ marginLeft: '8px' }}
            >
              Our Triple line power dialer dials your lists at a blazing speed
              of up to 300 Calls per-hour using our industry exclusive
              copper-based, hosted multi –line system. 300 calls per hour will
              get you more contacts than you can imagine while giving you the
              pipeline to close more deals, FAST! The best part, you only need
              ONE phone line to dial in to our system with, we turn that one
              line in to 3 on our end, EASY!
            </HelpPopover>
          </div>
          <div style={{ marginTop: '6px' }}>
            <Price price={149} />
          </div>
        </>
      );
      extraClass = styles.Dialer;
      break;
    default:
      cardContent = null;
  }

  const cardClasses = [styles.Card, styles[cardType]];
  if (isActive) {
    cardClasses.push(styles.Active);
  }

  if (extraClass) {
    cardClasses.push(extraClass);
  }

  return (
    <button
      type="button"
      className={cardClasses.join(' ')}
      style={{ ...style }}
      onClick={clicked}
    >
      <div className={styles.Circle}>
        {isActive ? <div className={styles.Inner} /> : null}
      </div>
      {cardContent}
    </button>
  );
};

export default ChoiceCard;
