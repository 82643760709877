import React from 'react';

import styles from './FooterCopyright.module.css';

const footerCopyright = ({ side }) => {
  return (
    <div className={[styles.Footer, side].join(' ')}>
      <div className={styles.Copyright}>
        Copyright © {new Date().getFullYear()}. Mojo Dialing Solutions LLC All
        rights reserved
      </div>
    </div>
  );
};

export default footerCopyright;
