import React from 'react';
import Prompt from '../Prompt/Prompt';
import appstoreImg from '../../../assets/images/signin/appstore.svg';
import googleplayImg from '../../../assets/images/signin/googleplay.svg';

const MobileAlert = () => {
  return (
    <Prompt
      isOpen
      style={{ width: '320px' }}
      title="Warning"
      onClose
      hideClose
      buttons={[]}
    >
      <div style={{ lineHeight: '1.5', fontSize: '22px' }}>
        <p>Your device is not compatible with this website.</p>
        <p>
          Please download the Mojo On The Go app to access your Mojo account
        </p>
        <div
          style={{
            textAlign: 'center',
            marginTop: '30px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://itunes.apple.com/us/app/mojo-on-the-go!/id1051036443"
            style={{ flexBasis: '45%' }}
          >
            <img src={appstoreImg} alt="" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.mojosells.mojoonthego"
            style={{ flexBasis: '45%' }}
          >
            <img src={googleplayImg} alt="" />
          </a>
        </div>
      </div>
    </Prompt>
  );
};

export default MobileAlert;
