import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import styles from './TrainingWebinars.module.scss';
import { checkWebinarsStatus } from '../../api/TrainingWebinars';

import morningWebinarImg from '../../assets/images/training_webinars/webinar-morning-icon.svg';
import afternoonWebinarImg from '../../assets/images/training_webinars/webinar-afternoon-icon.svg';

export default function TrainingWebinarsView() {
  const [webinars, setWebinars] = useState({});

  const isMorningWebinarActive = useMemo(() => {
    return webinars.morning && webinars.morning.is_active;
  }, [webinars]);
  const isAfternoonWebinarActive = useMemo(() => {
    return webinars.afternoon && webinars.afternoon.is_active;
  }, [webinars]);

  const checkWebinarStatus = async () => {
    const { data } = await checkWebinarsStatus();
    setWebinars(data.webinars);
  };

  useEffect(
    () => {
      checkWebinarStatus();

      // Run every 1 minute
      setInterval(() => {
        checkWebinarStatus();
      }, 1000 * 60 * 1);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div className={styles.container}>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className={styles.containerInner}>
        <div className={styles.title}>Training Webinars</div>

        <div className={styles.description} style={{ marginTop: '32px' }}>
          Start prospecting like a pro with Mojo&apos;s Live training webinars!
          Here an experienced user will share their expertise and insights on
          how to Navigate, Set Up and Use the Mojo Power Dialer and CRM quicky
          and efficiently. At the end of each training, we have a Q&A session to
          cover attendee specific needs or concerns.
        </div>
        <div className={styles.scheduleContainer} style={{ marginTop: '32px' }}>
          <div className={styles.scheduleItem}>
            <div className={styles.scheduleItemInner}>
              <div className={styles.scheduleItemHeader}>
                <div />
                <div style={{ textAlign: 'center' }}>
                  <img src={morningWebinarImg} alt="" />
                  <div className={styles.webinarType}>Morning Webinar</div>
                </div>
                <div
                  className={
                    isMorningWebinarActive ? styles.online : styles.offline
                  }
                  style={{ textAlign: 'right' }}
                >
                  {isMorningWebinarActive ? 'Active' : 'Offline'}
                </div>
              </div>

              <div className={styles.scheduleItemBody}>
                <div className={styles.textType1} style={{ marginTop: '16px' }}>
                  days
                </div>
                <div className={styles.textType2} style={{ marginTop: '8px' }}>
                  Monday, Tuesday, Wednesday, Thursday, Friday
                </div>
                <div
                  className={styles.hoursContainer}
                  style={{ marginTop: '18px' }}
                >
                  <div>
                    <div className={styles.textType1}>EST</div>
                    <div
                      className={styles.textType2}
                      style={{ marginTop: '6px' }}
                    >
                      11AM
                    </div>
                  </div>
                  <div>
                    <div className={styles.textType1}>CST</div>
                    <div
                      className={styles.textType2}
                      style={{ marginTop: '6px' }}
                    >
                      10AM
                    </div>
                  </div>
                  <div>
                    <div className={styles.textType1}>MST</div>
                    <div
                      className={styles.textType2}
                      style={{ marginTop: '6px' }}
                    >
                      9AM
                    </div>
                  </div>
                  <div>
                    <div className={styles.textType1}>PST</div>
                    <div
                      className={styles.textType2}
                      style={{ marginTop: '6px' }}
                    >
                      8AM
                    </div>
                  </div>
                </div>
                <div className={styles.joinBtnContainer}>
                  <a
                    href="https://us06web.zoom.us/j/3020606473?pwd=aCtwSjZNSXJhV2xYZU5hSGwxWVUwUT09"
                    target="_top"
                    className={[
                      styles.joinBtn,
                      isMorningWebinarActive
                        ? styles.joinBtn__online
                        : styles.joinBtn__offline,
                    ].join(' ')}
                  >
                    join
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.scheduleItem}>
            <div className={styles.scheduleItemInner}>
              <div className={styles.scheduleItemHeader}>
                <div />
                <div style={{ textAlign: 'center' }}>
                  <img src={afternoonWebinarImg} alt="" />
                  <div className={styles.webinarType}>Afternoon Webinar</div>
                </div>
                <div
                  className={
                    isAfternoonWebinarActive ? styles.online : styles.offline
                  }
                  style={{ textAlign: 'right' }}
                >
                  {isAfternoonWebinarActive ? 'Active' : 'Offline'}
                </div>
              </div>

              <div className={styles.scheduleItemBody}>
                <div className={styles.textType1} style={{ marginTop: '16px' }}>
                  days
                </div>
                <div className={styles.textType2} style={{ marginTop: '8px' }}>
                  Monday, Tuesday, Wednesday, Thursday, Friday
                </div>
                <div
                  className={styles.hoursContainer}
                  style={{ marginTop: '18px' }}
                >
                  <div>
                    <div className={styles.textType1}>EST</div>
                    <div
                      className={styles.textType2}
                      style={{ marginTop: '6px' }}
                    >
                      3PM
                    </div>
                  </div>
                  <div>
                    <div className={styles.textType1}>CST</div>
                    <div
                      className={styles.textType2}
                      style={{ marginTop: '6px' }}
                    >
                      2PM
                    </div>
                  </div>
                  <div>
                    <div className={styles.textType1}>MST</div>
                    <div
                      className={styles.textType2}
                      style={{ marginTop: '6px' }}
                    >
                      1PM
                    </div>
                  </div>
                  <div>
                    <div className={styles.textType1}>PST</div>
                    <div
                      className={styles.textType2}
                      style={{ marginTop: '6px' }}
                    >
                      12PM
                    </div>
                  </div>
                </div>
                <div className={styles.joinBtnContainer}>
                  <a
                    href="https://us06web.zoom.us/j/3020606473?pwd=aCtwSjZNSXJhV2xYZU5hSGwxWVUwUT09"
                    target="_top"
                    className={[
                      styles.joinBtn,
                      isAfternoonWebinarActive
                        ? styles.joinBtn__online
                        : styles.joinBtn__offline,
                    ].join(' ')}
                  >
                    join
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.description}>
          <p>
            Sessions open <span className={styles.bold}>15 minutes before</span>{' '}
            and close at <span className={styles.bold}>5 minutes past</span> the
            hour. Join us at your best convenience!
          </p>
          <p>
            To avoid interruptions, users will not be admitted after the session
            starts. If you can&apos;t attend the live session or didn&apos;t
            make it on time.
          </p>
          <p>
            DON&apos;T PANIC simply visit our Getting Started Section for a
            recorded walkthrough in the meantime:{' '}
            <a
              href="https://mojodialersupport.mojosells.com/portal/en/kb/articles/getting-started-video"
              target="_blank"
              rel="noreferrer"
              className={styles.knowledgeButton}
            >
              Knowledge Base
            </a>
          </p>
          <p className={styles.infoNote}>
            By selecting to “Join” you will be redirected to Zoom on a separate
            browser tab please follow Zoom&apos;s “launch meeting” instructions
            to be admitted to our personal meeting room.
          </p>
        </div>
      </div>
    </div>
  );
}
