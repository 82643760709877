import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/UI/Button/Button';
import Form from '../../../components/UI/Form/Form';
import CallTollFree from '../../../components/Shared/CallTollFree/CallTollFree';
import MobileAlert from '../../../components/Shared/MobileAlert/MobileAlert';
import AgentsPrompt from '../AgentsPrompt/AgentsPrompt';
import styles from './SignInData.module.css';
import * as api from '../../../api/SignIn';
import { detectIE, detectMobile, detectBrowser } from '../../../utils/utils';

const getMessageByStatus = (status, extraData) => {
  let content = null;
  switch (status) {
    case 'registered':
      content = (
        <div className={styles.SuccessWrap}>
          <div className={styles.SuccessTitle}>Success!</div>
          <div className={styles.SuccessContent}>
            Your account is currently being set up. This can take 5-10 minutes,
            please wait for your confirmation e-mail to log into Mojo.
          </div>
        </div>
      );
      break;
    case 'busy.active':
      content = (
        <div className={styles.SuccessWrap}>
          <div className={styles.SuccessTitle}>Oops!</div>
          <div className={styles.SuccessContent}>
            It appears you currently have an Active Mojo account under Account
            Number {extraData.pin}. <br />
            Please enter your password to login.
          </div>
        </div>
      );
      break;
    case 'logged_out_as_fraud':
      content = (
        <div className={styles.ErrorWrap}>
          <div className={styles.ErrorContent}>
            <span>Your Account has been </span>
            <b>Cancelled</b>
            <span> as a result of at least one of the following reasons. </span>
            <b>
              Chargeback/Disputed Charge, Fraudulent Charges, Account activity
              not deemed as acceptable usage.
            </b>
            <span>
              {' '}
              Please contact the Mojo Support Team 877-859-6656 Ext 2 if you
              have any further questions.
            </span>
          </div>
        </div>
      );
      break;
    case 'subscription_canceled':
      content = (
        <div className={styles.ErrorWrap}>
          <div className={styles.ErrorContent}>
            <span>
              Account suspended. Please pay for the services to continue using
              Mojo.
            </span>
          </div>
        </div>
      );
      break;
    case 'email':
      content = (
        <div className={styles.ErrorWrap}>
          <div className={styles.ErrorContent}>
            <span>Invalid login/password</span>
          </div>
        </div>
      );
      break;
    default:
      content = null;
  }

  return content;
};

const SignInData = () => {
  const [inputsScheme, setInputScheme] = useState({
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        placeholder: 'Enter your email',
      },
      value: '',
      validation: {
        required: true,
        isEmail: true,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Email:',
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Enter your password',
      },
      value: '',
      validation: {
        required: true,
        minLength: 6,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Password:',
    },
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState(null);
  const [submittedformData, setSubmittedformData] = useState(null);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [showMobileAlert, setShowMobileAlert] = useState(detectMobile());
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const statusSearchParam = searchParams.get('status');
  const extraDataParam = searchParams.get('extraData')
    ? JSON.parse(searchParams.get('extraData'))
    : {};

  const [ignoreStatusSearchParam, setIgnoreStatusSearchParam] = useState(false);

  const fetchAgents = useCallback(async (email) => {
    setIsLoading(true);
    try {
      const response = await api.checkAgentEmail(email);
      setAgents(response.data.login_data_list);
    } catch (e) {
      setErrors(e.message);
      setIgnoreStatusSearchParam(true);
    }
    setIsLoading(false);
  }, []);

  const doLogin = useCallback(async (formData) => {
    setIsLoading(true);
    try {
      const response = await api.signIn(formData);
      window.location.href = response.data.redirect_url;
    } catch (e) {
      if (e.response) {
        if (
          e.response.headers['content-type'] === 'application/json' &&
          e.response.data.errors
        ) {
          setErrors(e.response.data.errors);
        } else {
          setErrors('Network Error');
        }
        setIgnoreStatusSearchParam(true);
      } else {
        setErrors(e.message);
      }
    }
    setIsLoading(false);
  }, []);

  const clearState = useCallback(() => {
    setAgents(null);
    setSelectedAgentId(null);
    setSubmittedformData(null);
  }, []);

  const handleOnSubmit = useCallback(
    (event, formData, inputsScheme) => {
      event.preventDefault();
      // setSubmittedformData(formData);
      setSubmittedformData({
        ...formData,
        screenHeight: window.screen.height,
        screenWidth: window.screen.width,
        browser: detectBrowser().name,
        version: detectBrowser().version,
      });
      setInputScheme(inputsScheme);
    },
    [setInputScheme]
  );

  const handleOnAgentsPromptClose = useCallback(() => {
    clearState();
  }, [clearState]);

  const handleOnClickLoginOption = (agentId) => {
    setSelectedAgentId(agentId);
    setSubmittedformData({ ...submittedformData, pin: agentId });
  };

  const handleOnClickSignUpOption = useCallback(() => {
    history.push('/sign-up/');
  }, [history]);

  const handleOnShowMobileAlertClose = useCallback(() => {
    setShowMobileAlert(false);
  }, [setShowMobileAlert]);

  useEffect(() => {
    if (submittedformData) {
      if (!agents) {
        fetchAgents(submittedformData.email);
      } else if (selectedAgentId || (agents && agents.length <= 1)) {
        doLogin(submittedformData);
        clearState();
      }
    }
  }, [
    agents,
    submittedformData,
    selectedAgentId,
    doLogin,
    clearState,
    fetchAgents,
  ]);

  useEffect(
    () => {
      if (detectIE() && !statusSearchParam) {
        window.location.href = '/browser_select/';
      }

      if (extraDataParam.email) {
        const updatedInputsScheme = { ...inputsScheme };
        updatedInputsScheme.email.value = extraDataParam.email;
        setInputScheme(updatedInputsScheme);
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div className={styles.SignInData}>
      {showMobileAlert && (
        <MobileAlert onClose={handleOnShowMobileAlertClose} />
      )}
      {agents && agents.length > 1 && (
        <AgentsPrompt
          isOpen
          agents={agents}
          optionBtnLabel="Login"
          onClose={handleOnAgentsPromptClose}
          onLoginOptionClick={handleOnClickLoginOption}
          onSignUpOptionClick={handleOnClickSignUpOption}
        />
      )}
      <div className={styles.Title}>Sign In to Mojo</div>
      <div className={styles.SignInDataInner}>
        <div className={styles.SubTitle}>Sign In:</div>
        <Form
          inputsScheme={inputsScheme}
          onSubmit={handleOnSubmit}
          style={{ paddingTop: '18px' }}
          errorsScheme={errors}
        >
          <Button
            type="submit"
            isBtnNext
            style={{ marginTop: '30px', width: '146px' }}
            isLoading={isLoading}
          >
            Submit
          </Button>
          <a
            className={styles.ForgotYourPin}
            href="/password-reset/"
            style={{ marginLeft: '22px' }}
          >
            Forgot your Password?
          </a>
        </Form>
        {statusSearchParam &&
          !ignoreStatusSearchParam &&
          getMessageByStatus(statusSearchParam, extraDataParam)}
        <CallTollFree />
      </div>
    </div>
  );
};

export default SignInData;
