import React, { useState, useCallback } from 'react';

import Button from '../../../../components/UI/Button/Button';
import Radio from '../../../../components/UI/Inputs/Radio/Radio';
import Input from '../../../../components/UI/Input/Input';
import Prompt from '../../../../components/Shared/Prompt/Prompt';
import styles from './StepOne.module.css';
import { checkValidity, updateObject } from '../../../../shared/utility';
import * as api from '../../../../api/SignUp';

const AccountExists = (props) => {
  const [existingAccForm, setExistingAccForm] = useState({
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Enter password',
        autoComplete: 'off',
      },
      value: '',
      validation: {
        required: true,
        minLength: 6,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Enter Account Password:',
    },
  });
  const [accProcessingType, setAccProcessingType] = useState('reactivation');
  const [formIsValid, setFormIsValid] = useState(false);
  const [nonFieldErrors, setNonFieldErrors] = useState(null);
  const [isPasswordValidated, setIsPasswordValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lbSignUpUrl, setLbSignUpUrl] = useState(null);
  const { onPrev, onNewAccount, pin, email } = props;

  const submitHandler = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setNonFieldErrors(null);
    clearState();
    api
      .getLoginInfoByEmail(email, existingAccForm.password.value)
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === 'invalid_data') {
          setNonFieldErrors(response.data.message);
        } else if (accProcessingType === 'reactivation') {
          setIsPasswordValidated(true);
          window.location.href = response.data.force_login_url;
        } else if (accProcessingType === 'new_account') {
          setIsPasswordValidated(true);
          // setLbSignUpUrl(response.data.lb_sign_up_url);
          onNewAccount(response.data.lb_sign_up_token);
        } else {
          throw new Error('Invalid processingType option.');
        }
      });
  };

  const clearState = useCallback(() => {
    setIsPasswordValidated(false);
    setLbSignUpUrl(null);
  }, [setIsPasswordValidated, setLbSignUpUrl]);

  const onSignUpConfirmedHandler = () => {
    window.location.href = lbSignUpUrl;
  };

  const onSignUpCancelledHandler = () => {
    clearState();
  };

  const accProcessingChangedHandler = (event) => {
    switch (event.target.value) {
      case 'reactivation':
        setAccProcessingType('reactivation');
        break;
      case 'new_account':
        setAccProcessingType('new_account');
        break;
      default:
        throw new Error('Invalid target name.');
    }
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    const validationInfo = checkValidity(
      event.target.value,
      existingAccForm[inputIdentifier].validation
    );
    const updatedFormElement = updateObject(existingAccForm[inputIdentifier], {
      value: event.target.value,
      valid: validationInfo.isValid,
      error: validationInfo.error,
      touched: true,
    });

    const updatedForm = updateObject(existingAccForm, {
      [inputIdentifier]: updatedFormElement,
    });

    let formIsValidCheck = true;
    Object.keys(updatedForm).forEach((inputIdentifier) => {
      formIsValidCheck = updatedForm[inputIdentifier].valid && formIsValidCheck;
    });
    setExistingAccForm(updatedForm);
    setFormIsValid(formIsValidCheck);
  };
  // const isChecked = accProcessingType === 'reactivation' ? true : false;

  return (
    <div className={styles.Step}>
      {isPasswordValidated && accProcessingType === 'new_account' && (
        <Prompt
          isOpen
          style={{ width: '400px' }}
          title="Confirmation"
          onClose={onSignUpCancelledHandler}
          buttons={[
            {
              title: 'Cancel',
              clicked: onSignUpCancelledHandler,
              type: 'cancel',
            },
            {
              title: 'Yes',
              clicked: onSignUpConfirmedHandler,
              type: 'success',
            },
          ]}
        >
          <div style={{ lineHeight: '1.5' }}>
            Are you sure you want to start a new account? <br />
            (All previous data, reports and settings will be deleted)
          </div>
        </Prompt>
      )}
      <div className={styles.Title}>
        We found an existing Mojo account
        <br />
        <b>Account Number {pin}</b> associated with this email.
      </div>
      <div className={styles.Title} style={{ marginTop: '20px' }}>
        Please choose how you would like to proceed.
      </div>
      <form onSubmit={submitHandler}>
        <div className={styles.RadioInputWrap}>
          <Radio
            name="processing_type"
            value="reactivation"
            isChecked={accProcessingType === 'reactivation'}
            changed={accProcessingChangedHandler}
          >
            Would you like to reactivate?
          </Radio>
        </div>
        <div className={styles.RadioInputWrap}>
          <Radio
            name="processing_type"
            value="new_account"
            isChecked={accProcessingType === 'new_account'}
            changed={accProcessingChangedHandler}
          >
            Would you like to start a new account?
            <br />
            (All previous data, reports and settings
            <br />
            will be deleted)
          </Radio>
        </div>
        <Input
          name="password"
          label={existingAccForm.password.label}
          elementType={existingAccForm.password.elementType}
          elementConfig={existingAccForm.password.elementConfig}
          value={existingAccForm.password.value}
          invalid={!existingAccForm.password.valid}
          shouldValidate={existingAccForm.password.validation}
          touched={existingAccForm.password.touched}
          changed={(event) => inputChangedHandler(event, 'password')}
          error={existingAccForm.password.error}
          inputLabelStyle={{ width: '183px', marginRight: '30px' }}
        />
        {nonFieldErrors && (
          <div className={styles.NonFieldErrors}>{nonFieldErrors}</div>
        )}
        <div className={styles.PasswordResetTitle}>
          Don`t remember your password?{' '}
          <a href="/password-reset/">Reset it now!</a>
        </div>
        <div className={styles.ButtonsWrap}>
          <Button
            onClick={onPrev}
            isBtnPrev
            style={{ marginRight: '298px', width: '146px' }}
            disabled={isLoading}
          >
            Previous
          </Button>
          <Button
            type="submit"
            isBtnNext
            style={{ width: '146px' }}
            isLoading={isLoading}
            disabled={!formIsValid}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AccountExists;
