import React from 'react';
import Modal from 'react-modal';

import styles from './AgentsPrompt.module.scss';
import PopupCloseIcon from '../../../assets/icons/popup-close-icon.svg';

Modal.setAppElement('#root');
const customStyles = {
  content: {
    width: 640,
    height: 400,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 4,
    padding: '5px 20px',
    // For Dynamic Height
    // border: '0',
    // borderRadius: '4px',
    // bottom: 'auto',
    // minHeight: '10rem',
    // left: '50%',
    // padding: '2rem',
    // position: 'fixed',
    // right: 'auto',
    // top: '50%',
    // transform: 'translate(-50%,-50%)',
    // minWidth: '20rem',
    // width: '80%',
    // maxWidth: '60rem',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    boxShadow: '0 0 40px 0 rgba(0, 0, 0, 0.2)',
    zIndex: 1,
  },
};

const getStylesByStatusId = (statusId) => {
  const map = {
    'free signup agent': styles.StatusFreeSignUp,
    pending: styles.StatusPending,
    active: styles.StatusActive,
    expired: styles.StatusCancelled,
    canceled: styles.StatusCancelled,
    past_due: styles.StatusPastDue,
    '24h_past_due': styles.StatusPastDue,
    'free agent': styles.StatusFreeSignUp,
    'waiting for reactivation': styles.StatusPending,
  };
  return map[statusId];
};

export default function AgentsPrompt(props) {
  const {
    isOpen,
    agents,
    onSignUpOptionClick,
    onLoginOptionClick,
    optionBtnLabel,
    onClose,
  } = props;

  return (
    <Modal isOpen={isOpen} style={customStyles} shouldCloseOnOverlayClick>
      <div className={styles.ModalInner}>
        <div className={styles.ModalHeader}>
          <div className={styles.ModalHeaderLabel}>Choose account</div>
          <button
            className={styles.ModalHeaderCloseBtn}
            type="button"
            onClick={() => {
              onClose();
            }}
          >
            <img src={PopupCloseIcon} alt="close" />
          </button>
        </div>
        <div className={styles.ModalContent}>
          <div className={styles.ModalContentText}>
            This email is associated with multiple Mojo accounts. Please choose
            from the list below:
          </div>
          <table className={styles.AccountsTable}>
            <thead>
              <tr>
                <td>Company</td>
                <td>Owner Name </td>
                <td style={{ width: '55px' }}>Account Number </td>
                <td>Status </td>
                <td style={{ textAlign: 'center' }}>Option </td>
              </tr>
            </thead>
            <tbody>
              {agents.map((agent) => (
                <tr key={agent.id}>
                  <td>{agent.company}</td>
                  <td>{agent.name}</td>
                  <td>{agent.pin}</td>
                  <td>
                    <ul className={styles.StatusWrapper}>
                      <li
                        className={[
                          styles.Status,
                          getStylesByStatusId(agent.status_id),
                        ].join(' ')}
                      >
                        {agent.status}
                      </li>
                    </ul>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <button
                      className={styles.Btn}
                      type="button"
                      onClick={() => {
                        if (agent.is_should_sign_up) {
                          onSignUpOptionClick();
                        } else {
                          onLoginOptionClick(agent.id);
                        }
                      }}
                    >
                      {agent.is_should_sign_up ? 'Sign Up' : optionBtnLabel}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.ModelFooter}>
          <button
            className={styles.ModelFooterCloseBtn}
            type="button"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}
