import React from 'react';
import Cleave from 'cleave.js/react';

import styles from './PhoneInput.module.scss';

const PhoneInput = (props) => {
  const {
    name,
    value,
    changed,
    invalid,
    shouldValidate,
    touched,
    error,
    label,
    inputLabelStyle,
    refProp,
    autoComplete,
  } = props;
  const inputClasses = [styles.InputElement];
  const inputElementSpanClasses = [styles.inputElementSpanClasses];

  if (invalid && shouldValidate && touched) {
    inputClasses.push(styles.Invalid);
    inputElementSpanClasses.push(styles.Invalid);
  }

  return (
    <div className={styles.Input} ref={refProp}>
      <div className={styles.Label} style={inputLabelStyle}>
        {label}
      </div>
      <div className={styles.InputElementWrapper}>
        <span className={inputElementSpanClasses.join(' ')}>
          <Cleave
            name={name}
            value={value}
            options={{
              blocks: [0, 3, 3, 4],
              delimiters: ['(', ') ', '-'],
              numericOnly: true,
            }}
            autoComplete={autoComplete}
            onChange={changed}
            className={inputClasses.join(' ')}
          />
        </span>
        <div className={styles.InputElementError}>{error}</div>
      </div>
    </div>
  );
};

export default PhoneInput;
