import React, { useState, useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import Input from '../Input/Input';
import styles from './Form.module.css';
import { checkValidity, stringToJsx } from '../../../shared/utility';

export default function Form(props) {
  const {
    inputsScheme,
    errorsScheme,
    children,
    onSubmit,
    style,
    inputLabelStyle,
  } = props;
  const [updatedInputsScheme, setUpdatedInputsScheme] = useState({
    ...inputsScheme,
  });
  const updatedInputSchemeKeys = Object.keys(inputsScheme);
  const [nonFieldErrors, setNonFieldErrors] = useState(null);

  useEffect(() => {
    setUpdatedInputsScheme({
      ...inputsScheme,
    });
  }, [inputsScheme]);

  useEffect(
    () => {
      if (typeof errorsScheme === 'string') {
        setNonFieldErrors(stringToJsx(errorsScheme));
      } else if (typeof errorsScheme === 'object') {
        const localinputsScheme = {
          ...updatedInputsScheme,
        };

        // Update fields' errors
        Object.keys(localinputsScheme).forEach((controlName) => {
          if (controlName in errorsScheme) {
            localinputsScheme[controlName].valid = false;
            localinputsScheme[controlName].touched = true;
            localinputsScheme[controlName].error = errorsScheme[controlName];
          } else {
            localinputsScheme[controlName].valid = true;
            localinputsScheme[controlName].touched = true;
            localinputsScheme[controlName].error = '';
          }
        });

        if (Object.keys(localinputsScheme).length > 0) {
          setUpdatedInputsScheme((prevInputsScheme) => {
            return { ...prevInputsScheme, ...localinputsScheme };
          });
        }

        // If unrecognized error name provided -> treat it as "nonFieldErrors"
        Object.keys(errorsScheme).forEach((controlName) => {
          if (!(controlName in localinputsScheme)) {
            setNonFieldErrors(stringToJsx(errorsScheme[controlName]));
          }
        });
      }
      return () => {
        // Clear non field Errors. Every fields' errors will be updated inside of `setupErrors`
        setNonFieldErrors(null);
      };
    },
    // eslint-disable-next-line
    [inputsScheme, errorsScheme]
  );

  const handlerOnChange = (event, controlName) => {
    const validationResult = checkValidity(
      event.target.value,
      updatedInputsScheme[controlName].validation
    );
    const updatedInputs = {
      ...updatedInputsScheme,
      [controlName]: {
        ...updatedInputsScheme[controlName],
        value: event.target.value,
        valid: validationResult.isValid,
        error: validationResult.error,
        touched: true,
      },
    };
    setUpdatedInputsScheme(updatedInputs);
  };

  const formElementsArray = [];
  Object.values(updatedInputSchemeKeys).forEach((key) => {
    if (updatedInputsScheme[key]) {
      formElementsArray.push({
        id: key,
        config: updatedInputsScheme[key],
      });
    }
  });

  const formData = {};
  const inputList = [];
  Object.values(formElementsArray).forEach((formElement) => {
    formData[formElement.id] = formElement.config.value;

    inputList.push(
      <Input
        key={formElement.id}
        name={formElement.id}
        label={formElement.config.label}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        value={formElement.config.value}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={(event) => handlerOnChange(event, formElement.id)}
        error={formElement.config.error}
        inputLabelStyle={inputLabelStyle}
      />
    );
  });

  return (
    <div>
      <form
        autoComplete="off"
        onSubmit={(event) => onSubmit(event, formData, inputsScheme)}
        style={style}
      >
        <input
          autoComplete="off"
          name="hidden"
          type="text"
          style={{ display: 'none' }}
        />
        {inputList}
        {children}
      </form>
      {renderToStaticMarkup(nonFieldErrors) && (
        <div className={styles.NonFieldErrors}>{nonFieldErrors}</div>
      )}
    </div>
  );
}
