import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  prices: null,
  stepNumber: null,
  stepCompleted: null,
  accountType: 'single_user',
  dataAndDialerChoice: 'data_and_dialer',
  data: {
    email: null,
    hosted_lead_management_count: 1,
    single_line_power_dialer_count: 1,
    triple_line_power_dialer_count: 0,
    call_recording_feature_count: 0,
    call_me_feature_count: 0,
    neighborhood_search_feature: 0,
    fsbo_data_feature: 0,
    frbo_data_feature: 0,
    reverse_lookup_feature: 0,
    expired_data_feature: 0,
    pre_foreclosure_data_feature: 0,
  },
  formErrors: {
    emails_form: {},
    customer_form: {},
    payment_form: {},
    sign_up_form: {},
  },
  totalPrice: 99.0,
  error: null,
  misc: null,
  countries: null,
  states: null,
};

const getFeaturesTotalSum = (data, prices) => {
  let totalSum = 0.0;
  if (prices !== null) {
    if (data.hosted_lead_management_count !== null)
      totalSum +=
        data.hosted_lead_management_count * prices.HOSTED_LEAD_MANAGEMENT;
    if (data.single_line_power_dialer_count !== null)
      totalSum +=
        data.single_line_power_dialer_count * prices.SINGLE_LINE_POWER_DIALER;
    if (data.triple_line_power_dialer_count !== null)
      totalSum +=
        data.triple_line_power_dialer_count * prices.TRIPLE_LINE_POWER_DIALER;
    if (data.call_me_feature_count !== null)
      totalSum += data.call_me_feature_count * prices.CALL_ME_FEATURE;
    if (data.call_recording_feature_count !== null)
      totalSum +=
        data.call_recording_feature_count * prices.CALL_RECORDING_FEATURE;
    if (data.neighborhood_search_feature !== null)
      totalSum +=
        data.neighborhood_search_feature * prices.LEADSTORE_ONE_MONTH_PRICE;
    if (data.expired_data_feature !== null)
      totalSum +=
        data.expired_data_feature * prices.EXPIREDDATA_ONE_MONTH_PRICE;
    if (data.fsbo_data_feature !== null)
      totalSum += data.fsbo_data_feature * prices.FSBO_ONE_MONTH_PRICE;
    if (data.frbo_data_feature !== null)
      totalSum += data.frbo_data_feature * prices.FRBO_ONE_MONTH_PRICE;
    if (data.reverse_lookup_feature !== null)
      totalSum +=
        data.reverse_lookup_feature * prices.REVERSE_LOOKUP_ONE_MONTH_PRICE;
    if (data.pre_foreclosure_data_feature !== null)
      totalSum +=
        data.pre_foreclosure_data_feature *
        prices.PRE_FORECLOSURE_ONE_MONTH_PRICE;
  }
  return totalSum;
};

const setStepNumber = (state, action) => {
  return updateObject(state, {
    stepNumber: action.stepNumber,
  });
};

const setStepCompleted = (state, action) => {
  return updateObject(state, {
    stepCompleted: action.stepNumber,
  });
};

const setAccountEmail = (state, action) => {
  const updatedEmailAndLicences = updateObject(initialState.data, {
    email: action.emailValue,
  });
  return updateObject(state, {
    accountType: 'single_user',
    dataAndDialerChoice: 'data_and_dialer',
    data: updatedEmailAndLicences,
  });
};

const setAccountType = (state, action) => {
  let singleLineDefaultCount = null;
  switch (state.dataAndDialerChoice) {
    case 'data_and_dialer':
      singleLineDefaultCount = 1;
      break;
    case 'dialer':
      singleLineDefaultCount = 1;
      break;
    case 'data':
      singleLineDefaultCount = 0;
      break;
    default:
      singleLineDefaultCount = null;
  }
  const updatedLicences = {
    hosted_lead_management_count: action.accountType === 'multi_user' ? 2 : 1,
    single_line_power_dialer_count: singleLineDefaultCount,
    triple_line_power_dialer_count: 0,
    call_recording_feature_count: 0,
    call_me_feature_count: 0,
    neighborhood_search_feature: 0,
    fsbo_data_feature: 0,
    frbo_data_feature: 0,
    reverse_lookup_feature: 0,
    expired_data_feature: 0,
    pre_foreclosure_data_feature: 0,
  };
  const updatedData = updateObject(state.data, updatedLicences);

  const updatedSt = {
    data: updatedData,
    accountType: action.accountType,
  };

  if (state.prices !== null) {
    updatedSt.totalPrice = getFeaturesTotalSum(updatedData, state.prices);
  }

  return updateObject(state, updatedSt);
};

const setDataAndDialerChoice = (state, action) => {
  let singleLineDefaultCount = null;
  switch (action.dataAndDialerChoice) {
    case 'data_and_dialer':
      singleLineDefaultCount = 1;
      break;
    case 'dialer':
      singleLineDefaultCount = 1;
      break;
    case 'data':
      singleLineDefaultCount = 0;
      break;
    default:
      singleLineDefaultCount = 0;
  }
  const updatedLicences = {
    hosted_lead_management_count: state.accountType === 'multi_user' ? 2 : 1,
    single_line_power_dialer_count: singleLineDefaultCount,
    triple_line_power_dialer_count: 0,
    call_recording_feature_count: 0,
    call_me_feature_count: 0,
    neighborhood_search_feature: 0,
    fsbo_data_feature: 0,
    frbo_data_feature: 0,
    reverse_lookup_feature: 0,
    expired_data_feature: 0,
    pre_foreclosure_data_feature: 0,
  };
  const updatedData = updateObject(state.data, updatedLicences);
  const updatedSt = {
    data: updatedData,
    dataAndDialerChoice: action.dataAndDialerChoice,
  };

  if (state.prices !== null) {
    updatedSt.totalPrice = getFeaturesTotalSum(updatedData, state.prices);
  }
  return updateObject(state, updatedSt);
};

const setContext = (state, action) => {
  return updateObject(state, {
    prices: {
      ...action.prices,
    },
    misc: {
      ...action.misc,
    },
    error: null,
    countries: [...action.countries],
    states: [...action.states],
  });
};

const fetchContextFailed = (state, action) => {
  return updateObject(state, { error: action.error });
};

const updateSignUpData = (state, action) => {
  const updatedFields = { ...action.data };
  const updatedData = updateObject(state.data, updatedFields);

  const updatedState = { data: updatedData };

  if (state.prices !== null) {
    updatedState.totalPrice = getFeaturesTotalSum(updatedData, state.prices);
  }

  return updateObject(state, updatedState);
};

const signupFailed = (state, action) => {
  return updateObject(state, { formErrors: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_STEP_NUMBER:
      return setStepNumber(state, action);
    case actionTypes.SET_STEP_COMPLETED:
      return setStepCompleted(state, action);
    case actionTypes.SET_EMAIL:
      return setAccountEmail(state, action);
    case actionTypes.SET_ACCOUNT_TYPE:
      return setAccountType(state, action);
    case actionTypes.SET_DATA_AND_DIALER_CHOICE:
      return setDataAndDialerChoice(state, action);
    case actionTypes.SET_CONTEXT:
      return setContext(state, action);
    case actionTypes.FETCH_CONTEXT_FAILED:
      return fetchContextFailed(state, action);
    case actionTypes.UPDATE_SIGNUP_DATA:
      return updateSignUpData(state, action);
    case actionTypes.SIGNUP_FAILED:
      return signupFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
