import React from 'react';
import Spinner from 'react-loader-spinner';

export default function CustomSpinner({ height }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spinner type="Oval" color="#004093" height={height || 68} width={68} />
    </div>
  );
}
