import React from 'react';
import Parse from 'html-react-parser';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  let error = '';
  try {
    value = value.toString();
  } catch {
    value = '';
  }

  if (!rules) {
    return { isValid, error };
  }

  if (rules.required && isValid) {
    isValid = value.trim() !== '' && isValid;
    if (!isValid) {
      error = 'This field is required';
    }
  }

  if (
    rules.minLength &&
    isValid &&
    (rules.required || (!rules.required && value.length > 0))
  ) {
    isValid = value.length >= rules.minLength && isValid;
    if (!isValid) {
      error = `Minimum ${rules.minLength} characters required`;
    }
  }

  if (rules.maxLength && isValid) {
    isValid = value.length <= rules.maxLength && isValid;
    if (!isValid) {
      error = `Maximum ${rules.maxLength} characters required`;
    }
  }

  if (rules.isEmail && isValid) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value.toLowerCase()) && isValid;
    if (!isValid) {
      error = 'Enter a valid email address';
    }
  }

  if (rules.isPostalCode && isValid) {
    const pattern =
      /(^\d{5}(-\d{4})?$)|(^[A-Za-z0-9]{6}$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)/;
    isValid = pattern.test(value) && isValid;
    if (!isValid) {
      error = 'Enter a valid zip code';
    }
  }

  if (rules.isNumeric && isValid) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
    if (!isValid) {
      error = 'Enter a valid number';
    }
  }

  if (rules.isBoolean && isValid) {
    isValid = value.trim() !== 'false' && isValid;
    if (!isValid) {
      error = 'This field is required';
    }
  }

  if (rules.isPhone && isValid) {
    const prohibitedPhones = [
      '0000000000',
      '1111111111',
      '2222222222',
      '3333333333',
      '4444444444',
      '5555555555',
      '6666666666',
      '7777777777',
      '8888888888',
      '9999999999',
      '1234567890',
      '0123456789',
    ];
    const prohibitedEndingPhones = ['5551212'];
    if (prohibitedPhones.includes(value)) {
      isValid = false;
      error = 'This phone is prohibited';
    } else if (prohibitedEndingPhones.includes(value.substr(-7))) {
      isValid = false;
      error = 'This phone is prohibited';
    }
  }

  return { isValid, error };
};

export const stringToJsx = (errorsStr) => {
  const parsedErrors = Parse(errorsStr);
  const parsedErrorsJsx = [];

  Object.keys(parsedErrors).forEach((key) => {
    if (typeof parsedErrors[key] === 'object') {
      parsedErrorsJsx.push(
        React.createElement(parsedErrors[key].type, {
          ...parsedErrors[key].props,
          key,
        })
      );
    } else if (typeof parsedErrors[key] === 'string') {
      parsedErrorsJsx.push(<span key={key}>{parsedErrors[key]}</span>);
    }
  });
  return parsedErrorsJsx;
};
